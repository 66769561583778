import React, { PropsWithChildren } from "react";
import styled from "styled-components";

type BaseProps = BaseStyledComponentProps & {
  active?: boolean;
};

type BaseCmpProps = BaseProps & {
  tag: "span";
};

type ButtonProps = BaseProps &
  JSX.IntrinsicElements["button"] & {
    tag: "button";
  };

type AnchorProps = BaseProps &
  JSX.IntrinsicElements["a"] & {
    tag: "a";
  };

type TagProps = BaseCmpProps | ButtonProps | AnchorProps;

const UnstyledTag = (props: PropsWithChildren<TagProps>) => {
  const { tag, children, className } = props;
  const baseProps = { className };

  switch (tag) {
    case "button": {
      return (
        <button onClick={props.onClick} {...baseProps}>
          {children}
        </button>
      );
    }
    case "a": {
      return (
        <a href={props.href} {...baseProps}>
          {children}
        </a>
      );
    }
    case "span":
    default: {
      return <span {...baseProps}>{children}</span>;
    }
  }
};

export const Tag = styled(UnstyledTag)`
  font-size: 14px;
  line-height: 22px;
  padding: 4px 16px;
  white-space: nowrap;
  display: inline-flex;
  font-family: ${(props) => (props.active ? "PoppinsMedium" : "PoppinsRegular")},
    sans-serif;
  font-weight: ${(props) => (props.active ? 500 : 400)};
  border-radius: 32px;
  border: 1px solid ${(props) => (props.active ? "#45BE31" : "#C0C1C6")};
  box-shadow: ${(props) =>
    props.active
      ? "-4px 4px 16px rgba(91, 124, 85, 0.1), 4px -4px 16px rgba(91, 124, 85, 0.1);"
      : "none"};
  cursor: ${(props) =>
    props.tag === "button" || props.tag === "a" ? "pointer" : "default"};

  &,
  &:link,
  &:visited,
  &:hover,
  &:active {
    color: #2c3141;
    text-decoration: none;
  }
`;
