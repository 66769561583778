import { AnimatePresence } from "framer-motion";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { RouteAnimationContainer } from "./components/utils/RouteAnimationContainer";
import { useCampDay } from "./hooks/useCampDay";
import { Addresses } from "./routes/addresses/Addresses.route";
import { Apps } from "./routes/apps/Apps.route";
import { Home } from "./routes/home/Home.route";
import { Map } from "./routes/map/Map.route";
import { Numbers } from "./routes/numbers/Numbers.route";
import { Restaurants } from "./routes/restaurants/Restaurants.route";

export const AppRoutes = () => {
  const location = useLocation();
  const campDay = useCampDay();
  const normalizedLocation = location.pathname.includes("days")
    ? "days"
    : location.pathname;

  return (
    <AnimatePresence mode="wait" initial={false}>
      <Routes location={location} key={normalizedLocation}>
        <Route
          path="/"
          element={<Navigate to={`/days/${campDay}`} replace={true} />}
        />
        <Route
          path="/office-map"
          element={
            <RouteAnimationContainer>
              <Map />
            </RouteAnimationContainer>
          }
        />
        <Route
          path="/numbers"
          element={
            <RouteAnimationContainer>
              <Numbers />
            </RouteAnimationContainer>
          }
        />
        <Route
          path="/addresses"
          element={
            <RouteAnimationContainer>
              <Addresses />
            </RouteAnimationContainer>
          }
        />
        <Route
          path="/restaurants"
          element={
            <RouteAnimationContainer>
              <Restaurants />
            </RouteAnimationContainer>
          }
        />
        <Route
          path="/apps"
          element={
            <RouteAnimationContainer>
              <Apps />
            </RouteAnimationContainer>
          }
        />
        <Route
          path="/*"
          element={
            <RouteAnimationContainer>
              <Home />
            </RouteAnimationContainer>
          }
        ></Route>
      </Routes>
    </AnimatePresence>
  );
};
