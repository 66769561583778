import styled from "styled-components";

interface Props {
  className?: string;
}

const UnstyledWoodpeckerCampLogo: React.FC<Props> = ({ className }) => (
  <div className={className}>
    <span>
      <svg
        width="25"
        height="40"
        viewBox="0 0 25 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.829 26.028c3.974 3.004 8.257 5.076 9.95 5.853a.306.306 0 0 0 .473-.214.313.313 0 0 0-.038-.2c-.538-1.347-1.82-4.351-3.795-8.676a3.867 3.867 0 0 1-.308-2.02c.95-7.225-3.41-9.09-7.128-11.342h-.052c-1.41-.7-5.564-2.926-6.282-8.598a.26.26 0 0 0-.153-.176.254.254 0 0 0-.232.021C2.52 3.654 1.777 9.118 4.367 12.277a.468.468 0 0 1-.193.746.458.458 0 0 1-.295.005c-.846-.233-1.692-.75-2.051-1.268a.18.18 0 0 0-.333.077c-.513 1.684.05 3.393.666 4.325a.834.834 0 0 1 0 1.01c-3.18 4.35-1.769 10.073 1.334 13.725a.416.416 0 0 0 .754-.15.423.423 0 0 0-.062-.316c-.872-1.761-1.872-4.3-1.923-6.785v-.052a6.24 6.24 0 0 1 .556-2.656 6.184 6.184 0 0 1 1.627-2.16 6.108 6.108 0 0 1 2.387-1.25 6.07 6.07 0 0 1 2.687-.097c.4.06.795.156 1.18.285h.025a.559.559 0 0 0 .692-.3.574.574 0 0 0 .052-.27 4.997 4.997 0 0 0-1.436-3.444.415.415 0 0 1 .307-.725c2.36.103 6.693 1.087 6.334 6.914-.102 1.916-2.154 2.512-2.615 2.719-.338.152-.627.4-.831.711a1.982 1.982 0 0 0-.323 1.05c-.003.326.076.646.23.932l.052.078c.127.21.292.395.487.544l.154.103Z"
          fill="#231F20"
        ></path>
        <path
          d="M20.828 35.3a5.584 5.584 0 0 0-.64-2.227c-.275-.521-.62-1-1.026-1.425a6.777 6.777 0 0 0-3.334-1.839l-.256-.077c-7.77-2.02-9.155-5.594-9.232-7.666a.547.547 0 0 0-.42-.532.533.533 0 0 0-.605.3c-1.513 2.874.974 7.198 1.512 7.872 4.898 6.293 11.335 5.542 12.155 9.685a.26.26 0 0 0 .162.195.254.254 0 0 0 .248-.04c.263-.24.488-.519.667-.828.612-.98.882-2.137.77-3.289v-.13Z"
          fill="#231F20"
        ></path>
      </svg>
    </span>
    <p>
      Cold Email <span>Camp</span>
    </p>
  </div>
);

export const WoodpeckerColdEmailCampLogo = styled(UnstyledWoodpeckerCampLogo)`
  display: inline-flex;
  align-items: center;
  color: #45be31;
  > span {
    width: 48px;
    height: 48px;
    border-radius: 5px;
    color: inherit;
    background-color: currentColor;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }

  > p {
    font-family: "PoppinsSemiBold", sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-left: 16px;
    color: #0b0204;
    > span {
      font-weight: 500;
      font-family: "HeptaSlabMedium", sans-serif;
    }
  }
`;
