import styled from "styled-components";
import { Link, useParams } from "react-router-dom";
import { useRef, useState } from "react";
import { useClickAway } from "react-use";
import { NarrowContainer } from "../../components/NarrowContainer";
import { ReactComponent as CalendarIcon24x24 } from "../../assets/icons/calendar-24x24.svg";
import { ReactComponent as GmailIcon24x24 } from "../../assets/icons/gmail-24x24.svg";
import { ReactComponent as AppleIcon24x24 } from "../../assets/icons/apple-24x24.svg";
import { ReactComponent as OutlookIcon24x24 } from "../../assets/icons/outlook-24x24.svg";

const CalendarPickerPopoverLink = styled.a.attrs(() => ({
  target: "_blank",
  rel: "noreferrer",
}))`
  display: flex;
  align-items: center;
  text-align: left;
  background-color: #fff;
  padding: 6px 16px;
  &,
  &:link,
  &:visited,
  &:hover,
  &:active {
    text-decoration: none;
    color: #000;
  }

  svg {
    margin-right: 12px;
  }
`;

interface CalendarPickerPopoverProps extends BaseStyledComponentProps {
  isActive?: boolean;
}

const GOOGLE_CALENDAR_ICS_URL =
  "https://calendar.google.com/calendar/ical/c_3gop1oqrf047vhs2kn4k0opht8%40group.calendar.google.com/public/basic.ics";

const UnstyledCalendarPickerPopover = (props: CalendarPickerPopoverProps) => {
  const { className } = props;

  return (
    <ul className={className}>
      <li>
        <CalendarPickerPopoverLink
          href={`https://calendar.google.com/calendar/r?cid=${GOOGLE_CALENDAR_ICS_URL}`}
          title="Sync calendar to Google"
        >
          <GmailIcon24x24 />
          Google
        </CalendarPickerPopoverLink>
      </li>
      <li>
        <CalendarPickerPopoverLink
          href="/assets/calendar.ics"
          title="Sync calendar to Apple"
          download
        >
          <AppleIcon24x24 />
          Apple
        </CalendarPickerPopoverLink>
      </li>
      <li>
        <CalendarPickerPopoverLink
          href="/assets/calendar.ics"
          title="Sync calendar to Outlook"
          download
        >
          <OutlookIcon24x24 />
          Outlook
        </CalendarPickerPopoverLink>
      </li>
    </ul>
  );
};

const CalendarPickerPopover = styled(UnstyledCalendarPickerPopover)`
  border: 1px solid #eaeaec;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: -4px 4px 16px rgba(91, 124, 85, 0.1),
    4px -4px 16px rgba(91, 124, 85, 0.1);
`;

interface CalendarPickerProps extends BaseStyledComponentProps {}

const UnstyledCalendarPicker = (props: CalendarPickerProps) => {
  const { className } = props;
  const [isOpened, setOpened] = useState(false);
  const buttonRef = useRef<HTMLDivElement | null>(null);
  useClickAway(buttonRef, () => {
    if (isOpened) {
      setOpened(false);
    }
  });

  return (
    <div className={`${className} ${isOpened ? "active" : ""}`} ref={buttonRef}>
      <button onClick={() => setOpened((prev) => !prev)}>
        <CalendarIcon24x24 />
        Sync calendar
      </button>
      {isOpened && <CalendarPickerPopover />}
    </div>
  );
};

export const CalendarPicker = styled(UnstyledCalendarPicker)`
  position: relative;
  button {
    background-color: transparent;
    border: 1px solid #eaeaec;
    border-radius: 5px;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 24px;
    color: #0b0204;
    font-family: "PoppinsRegular", sans-serif;
    font-weight: 400;
    padding: 7px 16px;
    width: 100%;
    svg {
      margin-right: 8px;
    }
  }

  &.active button {
    background-color: #ffffff;
  }

  ${CalendarPickerPopover} {
    top: -40px;
    position: absolute;
    width: 182px;
    right: 10px;
  }
`;

interface DayConfig {
  to: string;
  isActive: boolean;
  date: string;
  dayOfWeek: string;
}

type UnstyledDayProps = BaseStyledComponentProps & DayConfig;

const UnstyledDay = (props: UnstyledDayProps) => {
  const { className, to, date, dayOfWeek } = props;

  return (
    <Link to={to} className={className} replace={true}>
      <article>
        <p>
          {date}
          <span>{dayOfWeek}</span>
        </p>
      </article>
    </Link>
  );
};

const Day = styled(UnstyledDay).attrs(
  (props: Pick<UnstyledDayProps, "isActive">) => ({
    isActive: props.isActive || false,
  })
)`
  display: flex;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 14px 6px;
  width: 58px;
  height: 70px;
  text-align: center;
  box-shadow: ${(props) =>
    props.isActive
      ? "-4px 4px 16px rgba(91, 124, 85, 0.1), 4px -4px 16px rgba(91, 124, 85, 0.1);"
      : "none"};

  &,
  &:link,
  &:visited,
  &:hover,
  &:active {
    text-decoration: none;
    color: #000;
  }

  p {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 18px;
    font-family: ${(props) =>
      props.isActive
        ? "PoppinsSemiBold, sans-serif"
        : "PoppinsRegular, sans-serif"};
    font-weight: ${(props) => (props.isActive ? "600" : "400")};
    > span {
      margin-top: 2px;
    }
  }
`;

interface UnstyledDayListProps extends BaseStyledComponentProps {
  days: DayConfig[];
}

const UnstyledDayList = (props: UnstyledDayListProps) => {
  const { className, days } = props;
  const { day } = useParams();

  return (
    <ul className={className}>
      {days.map((dayConfig, i) => (
        <li key={i}>
          <Day {...dayConfig} isActive={Number(day) === i + 1}></Day>
        </li>
      ))}
    </ul>
  );
};

const DayList = styled(UnstyledDayList)`
  display: flex;
  justify-content: space-between;
`;

interface UnstyledCalendarProps extends BaseStyledComponentProps {
  days: DayConfig[];
}

const UnstyledCalendar = (props: UnstyledCalendarProps) => {
  const { className, days } = props;

  return (
    <div className={className}>
      <NarrowContainer>
        <h2>Calendar & schedule</h2>
        <CalendarPicker />
        <DayList days={days} />
      </NarrowContainer>
    </div>
  );
};

export const Calendar = styled(UnstyledCalendar)`
  padding: 32px 0 calc(32px + 30px);
  background-color: #f8fdf8;
  h2 {
    font-weight: 600;
    font-family: "PoppinsSemiBold", sans-serif;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 12px;
  }

  ${CalendarPicker} {
    margin-bottom: 32px;
  }
`;
