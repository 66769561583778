import styled from "styled-components";
import { DESKTOP_MAX_WIDTH, MOBILE_MAX_WIDTH } from "../constants/styles";

export const VeryNarrowContainer = styled.div`
  padding: 0 32px;
  max-width: ${MOBILE_MAX_WIDTH};
  margin: 0 auto;
  width: 100%;
  @media (min-width: 1200px) {
    padding: 0;
    max-width: none;
    width: ${DESKTOP_MAX_WIDTH};
  }
`;
