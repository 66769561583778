import {
  PropsWithChildren,
  useMemo,
  useEffect,
  useState,
  useLayoutEffect,
} from "react";
import styled, { css, createGlobalStyle } from "styled-components";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { AnimatePresence, motion } from "framer-motion";
import { Link, useLocation } from "react-router-dom";
//
import { BackButtonBar } from "../../components/utils/BackButtonBar";
import { ReactComponent as MapGroundFloor } from "../../assets/img/map-ground-floor.svg";
import { ReactComponent as Map1stFloor } from "../../assets/img/map-1st-floor.svg";
import { ReactComponent as ArrowsLeftIcon22x22 } from "../../assets/icons/arrows-left-22x22.svg";
import { ContentContainer } from "../../components/utils/ContentContainer";
import {
  BREAKPOINTS,
  DESKTOP_MAX_WIDTH,
  MOBILE_MAX_WIDTH,
  SUPPORTS_HOVER,
} from "../../constants/styles";
import { MobileOnly } from "../../components/utils/MobileOnly";
import { DesktopOnly } from "../../components/utils/DesktopOnly";
import { Container } from "../../components/Container";

interface MapButtonProps {
  isActive: boolean;
  right: number;
  bottom: number;
}

const MapButton = styled.button.attrs((props: MapButtonProps) => ({
  isActive: props.isActive || false,
  bottom: props.bottom || 0,
  right: props.right || 0,
}))`
  width: 36px;
  height: 36px;
  font-family: "PoppinsSemiBold", sans-serif;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  right: ${(props) => `${props.right}px`};
  bottom: ${(props) => `${props.bottom}px`};
  background-color: ${(props) => (props.isActive ? "#0B0204" : "#F9F9F9")};
  color: ${(props) => (props.isActive ? "#FFF" : "#000")};
  filter: ${(props) =>
    props.isActive
      ? "none"
      : "drop-shadow(-4px 4px 16px rgba(91, 124, 85, 0.1)) drop-shadow(4px -4px 16px rgba(91, 124, 85, 0.1))"};
`;

const AnimationContainer = (props: PropsWithChildren) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    style={{
      width: "100%",
    }}
  >
    {props.children}
  </motion.div>
);

interface MapProps extends BaseStyledComponentProps {}

const CAFETERIA_MAP_AREA = "cafeteria";
const DINING_ROOM_MAP_AREA = "dining-room";
const WORKAREA_MAP_AREA = "work-area";
const STUDIO_MAP_AREA = "studio";

type Area =
  | typeof STUDIO_MAP_AREA
  | typeof DINING_ROOM_MAP_AREA
  | typeof WORKAREA_MAP_AREA
  | typeof CAFETERIA_MAP_AREA;

type AreaHash = `#${Area}`;

const resolveFloor = (area: AreaHash) => {
  switch (area) {
    case "#studio":
    case "#dining-room": {
      return 2;
    }
    case "#cafeteria":
    case "#work-area": {
      return 1;
    }
    default: {
      return 1;
    }
  }
};

type OfficeLocation =
  | {
      floor: 2;
      area: typeof STUDIO_MAP_AREA;
    }
  | {
      floor: 2;
      area: typeof DINING_ROOM_MAP_AREA;
    }
  | {
      floor: 1;
      area: typeof WORKAREA_MAP_AREA;
    }
  | {
      floor: 1;
      area: typeof CAFETERIA_MAP_AREA;
    }
  | {
      floor: 1 | 2;
      area: null;
    };

const resolveOfficeLocation = (areaHash: AreaHash): OfficeLocation => {
  switch (areaHash) {
    case "#cafeteria": {
      return {
        floor: resolveFloor("#cafeteria") as 1,
        area: "cafeteria",
      };
    }
    case "#work-area": {
      return {
        floor: resolveFloor("#work-area") as 1,
        area: "work-area",
      };
    }
    case "#studio": {
      return {
        floor: resolveFloor("#studio") as 2,
        area: "studio",
      };
    }
    case "#dining-room": {
      return {
        floor: resolveFloor("#dining-room") as 2,
        area: "dining-room",
      };
    }
    default: {
      return {
        floor: 1,
        area: null,
      };
    }
  }
};

const IS_ANIMATED_CSS_CLASS = "is-animated";

const animationNameCreator = (area: string) => `${area}-animation`;

const mapAreaAnimationKeyframesCreator = (
  area: string,
  initColor: string,
  targetColor: string
) => css`
  @keyframes ${animationNameCreator(area)} {
    0% {
      fill: ${initColor};
    }

    50% {
      fill: ${targetColor};
    }

    100% {
      fill: ${initColor};
    }
  }
`;

const MapElAnimationStyles = createGlobalStyle`
  ${mapAreaAnimationKeyframesCreator("cafeteria", "#EAEEFB", "#88A4FF")}
  ${mapAreaAnimationKeyframesCreator("dining-room", "#EAEEFB", "#88A4FF")}
  ${mapAreaAnimationKeyframesCreator("studio", "#EAF6E8", "#A3DB99")}
  ${mapAreaAnimationKeyframesCreator("work-area", "#FBF0DD", "#F1D39F")}

  .${IS_ANIMATED_CSS_CLASS} {
    animation-duration: 1.2s;
    animation-iteration-count: 5;
    animation-timing-function: ease-in-out;
  }
  
  .${CAFETERIA_MAP_AREA}.${IS_ANIMATED_CSS_CLASS} {
    animation-name: ${animationNameCreator("cafeteria")};
  }
  
  .${DINING_ROOM_MAP_AREA}.${IS_ANIMATED_CSS_CLASS} {
    animation-name: ${animationNameCreator("dining-room")};
  }
  
  .${WORKAREA_MAP_AREA}.${IS_ANIMATED_CSS_CLASS} {
    animation-name: ${animationNameCreator("work-area")};
  }
  
  .${STUDIO_MAP_AREA}.${IS_ANIMATED_CSS_CLASS} {
    animation-name: ${animationNameCreator("studio")};
  }
`;

export const UnstyledMap = (props: MapProps) => {
  const { className } = props;
  const location = useLocation();
  const hasHash = useMemo(() => Boolean(location.hash), []);
  const [currentOfficeLocation, setCurrentOfficeLocation] =
    useState<OfficeLocation>({
      floor: resolveFloor(location.hash as AreaHash),
      area: null,
    });
  useLayoutEffect(() => {
    if (location.hash) {
      setCurrentOfficeLocation(
        resolveOfficeLocation(location.hash as AreaHash)
      );
    }
  }, [location.hash]);
  useEffect(() => {
    if (currentOfficeLocation.area) {
      const $el = document.querySelector<SVGPathElement>(
        `.${currentOfficeLocation.area}`
      );

      if ($el) {
        $el.classList.add(IS_ANIMATED_CSS_CLASS);
      }
    }
  }, [currentOfficeLocation.area]);

  return (
    <ContentContainer>
      <div className={className}>
        <MobileOnly>
          <BackButtonBar />
        </MobileOnly>
        <DesktopOnly>
          <Container>
            {hasHash && (
              <Link className="back-link" to="/">
                <ArrowsLeftIcon22x22 />
                back to calendar & schedule
              </Link>
            )}
          </Container>
        </DesktopOnly>
        <div className="container">
          <MapElAnimationStyles />
          <TransformWrapper initialScale={1} maxScale={4} centerOnInit={true}>
            {({ zoomIn, zoomOut, resetTransform, centerView }) => (
              <>
                <TransformComponent>
                  <div className="map-container">
                    <AnimatePresence
                      mode="wait"
                      onExitComplete={() => {
                        resetTransform();
                        centerView();
                      }}
                    >
                      {currentOfficeLocation.floor === 1 && (
                        <AnimationContainer key={1}>
                          <MapGroundFloor />
                        </AnimationContainer>
                      )}
                      {currentOfficeLocation.floor === 2 && (
                        <AnimationContainer key={2}>
                          <Map1stFloor />
                        </AnimationContainer>
                      )}
                    </AnimatePresence>
                  </div>
                </TransformComponent>

                <MapButton
                  right={26}
                  bottom={178}
                  isActive={currentOfficeLocation.floor === 1}
                  onClick={() =>
                    setCurrentOfficeLocation({
                      floor: 1,
                      area: null,
                    })
                  }
                >
                  1
                </MapButton>
                <MapButton
                  right={26}
                  bottom={131}
                  isActive={currentOfficeLocation.floor === 2}
                  onClick={() =>
                    setCurrentOfficeLocation({
                      floor: 2,
                      area: null,
                    })
                  }
                >
                  2
                </MapButton>
                <MapButton right={26} bottom={77} onClick={() => zoomIn()}>
                  +
                </MapButton>
                <MapButton right={26} bottom={32} onClick={() => zoomOut()}>
                  -
                </MapButton>
              </>
            )}
          </TransformWrapper>
        </div>
      </div>
    </ContentContainer>
  );
};

export const Map = styled(UnstyledMap)`
  .container {
    max-width: ${MOBILE_MAX_WIDTH};
    margin: 0 auto;
    width: 100%;
    overflow: visible;
    display: flex;
    justify-content: center;
    @media ${BREAKPOINTS.DESKTOP} {
      max-width: none;
    }
  }

  .back-link {
    display: flex;
    align-items: center;
    &,
    &:link,
    &:visited,
    &:hover,
    &:active {
      text-decoration: none;
      color: #0b0204;
    }

    @media ${SUPPORTS_HOVER} {
      &:hover {
        text-decoration: underline;
      }
    }

    svg {
      margin-right: 4px;
    }
  }

  .map-container {
    min-height: calc(100 * var(--vh, 1vh) - 138px);
    padding: 0 16px;
    overflow: visible;
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 auto;
    @media ${BREAKPOINTS.DESKTOP} {
      width: ${DESKTOP_MAX_WIDTH};
      padding: 0;
    }

    svg {
      max-width: 100%;
      position: relative;
      top: -50px;
      @media ${BREAKPOINTS.DESKTOP} {
        top: -25px;
        width: 100%;
      }
    }
  }
`;
