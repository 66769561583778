export const fixVh = () => {
  const setVh = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
  window.addEventListener('resize', setVh);
  setVh();
}

export const fixVw = () => {
  const setVw = () => {
    let vw = document.documentElement.clientWidth * 0.01;
    document.documentElement.style.setProperty('--vw', `${vw}px`);
  }
  window.addEventListener('resize', setVw);
  setVw();
}