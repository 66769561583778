import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { useDesktop } from "../hooks/useDesktop";
import { Header } from "./Header";
import { MobileNav } from "./MobileNav";

interface HeaderWithMobileNavProps extends BaseStyledComponentProps {}

const UnstyledHeaderWithMobileNav = (props: HeaderWithMobileNavProps) => {
  const { className } = props;
  const location = useLocation();
  const [isMobileNavOpened, setMobileNavOpened] = useState(false);
  const [cachedRoute, setCachedRoute] = useState<string>();
  const isDesktop = useDesktop();
  useEffect(() => {
    setMobileNavOpened(false);
  }, [isDesktop]);

  useEffect(() => {
    setCachedRoute(location.pathname);
  }, [location]);

  useEffect(() => {
    if (isMobileNavOpened && location.pathname !== cachedRoute) {
      setMobileNavOpened(false);
      setCachedRoute(location.pathname);
    }

    if (!isMobileNavOpened && location.pathname === cachedRoute) {
      setMobileNavOpened(false);
    }
  }, [isMobileNavOpened, cachedRoute, location.pathname]);

  return (
    <div className={className}>
      <div className="header">
        <Header
          isMobileNavOpened={isMobileNavOpened}
          onMobileNavToggle={() => setMobileNavOpened((prev) => !prev)}
        />
      </div>
      <div className="mobile-nav">
        <MobileNav isOpened={isMobileNavOpened} />
      </div>
    </div>
  );
};

export const HeaderWithMobileNav = styled(UnstyledHeaderWithMobileNav)`
  position: relative;
  z-index: 2;
  .header {
    position: relative;
    z-index: 2;
  }

  .mobile-nav {
    position: relative;
    z-index: 1;
    top: -83px;
  }
`;
