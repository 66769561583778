import { motion } from "framer-motion";
import { PropsWithChildren } from "react";

const MOTION_CONFIG = {
  animate: { opacity: 1, x: 0, transition: { duration: 0.35 } },
  initial: { opacity: 0, x: 20 },
  exit: { opacity: 0, x: -20, transition: { duration: 0.2 } },
};

export const AnimationContainer: React.FC<PropsWithChildren> = (props) => {
  const { children } = props;

  return <motion.div {...MOTION_CONFIG}>{children}</motion.div>;
};
