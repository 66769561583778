import charlottePhoto from "../../assets/img/charlotte.jpg";
import bulkaZMaslemPhoto from "../../assets/img/bulka-z-maslem.jpg";
import podPapugamiPhoto from "../../assets/img/pod-papugami.jpg";
import szajnochy11Photo from "../../assets/img/szajnochy-11.jpg";
import wKontakciePhoto from '../../assets/img/w-kontakcie.jpg';
import soczewkaPhoto from '../../assets/img/soczewka.jpg';
import fallaPhoto from '../../assets/img/falla.jpg';
import vaffaNapoliPhoto from '../../assets/img/vaffa-napoli.jpg';
import woosabiPhoto from '../../assets/img/woosabi.jpg';
import orzoPhoto from '../../assets/img/orzo.jpg';
import concordiaTarasPhoto from '../../assets/img/concordia-taras.jpg';
import mlodaPolskaPhoto from '../../assets/img/mloda-polska.jpg';
import mercadoTapasPhoto from '../../assets/img/mercado-tapas-bistro.jpg';
import drevnyKocurPhoto from '../../assets/img/drevny-kocur.jpg';
import mangoMamaPhoto from '../../assets/img/mango-mama.jpg';
import theCorkPhoto from '../../assets/img/the-cork.jpg';
import laMaddalenaPhoto from '../../assets/img/la-maddalena.jpg';
import pierogarniaStaryMlynPhoto from '../../assets/img/pierogarnia-stary-mlyn.jpg';
import etnoPhoto from '../../assets/img/etno-cafe.jpg';
import wilkSytyPhoto from '../../assets/img/wilk-syty.jpg';

export const RESTAURANT_TYPES = [
  "asian food",
  "indian food",
  "italian food",
  "breakfast",
  "bar",
  "plant cuisine",
  "fine dining",
  "polish food",
  "casual dinner",
  "seasonal food",
  "dinner",
  "coffe shop",
  "wine",
  "lunch",
  'sushi'
] as const;

export interface RestaurantConfig {
  type: typeof RESTAURANT_TYPES[number][];
  name: string;
  description: string;
  menuUrl: string;
  location: {
    title: string;
    mapUrl: string;
  };
  img: string;
}

export const RESTAURANTS_LIST: RestaurantConfig[] = [
  {
    type: ["plant cuisine", 'coffe shop', 'breakfast', 'wine', 'lunch', 'bar'],
    name: "Charlotte",
    description: "French specialties, wine and bread",
    menuUrl: "https://bistrocharlotte.com/pl/lokale/wroclaw/",
    location: {
      title: "Świętego Antoniego 2/4",
      mapUrl: "https://goo.gl/maps/d9xanQw1kANkY1M48",
    },
    img: charlottePhoto,
  },
  {
    type: ["plant cuisine", 'seasonal food', 'dinner', 'bar', 'coffe shop', 'breakfast', 'wine', 'lunch'],
    name: "Bułka z Masłem",
    description: "Seasonal and regional cuisine",
    menuUrl: "http://bulkazmaslemwlodkowica.pl/",
    location: {
      title: "Pawła Włodkowica 8A",
      mapUrl: "https://g.page/bulkazmaslemwroclaw?share",
    },
    img: bulkaZMaslemPhoto,
  },
  {
    type: ["casual dinner", 'fine dining', 'bar'],
    name: "Pod Papugami",
    description: "Restaurant & Cocktail Bar",
    menuUrl: "https://podpapugami.com.pl/en/",
    location: {
      title: "Rynek, ul. Sukiennice 9a",
      mapUrl: "https://g.page/podpapugami?share",
    },
    img: podPapugamiPhoto,
  },
  {
    type: ['plant cuisine', 'coffe shop', 'breakfast', 'lunch'],
    name: "W kontakcie",
    description: "Humus, breakfast and other veggie dishes",
    menuUrl: "https://www.wkontakciewroclaw.pl/",
    location: {
      title: "Michała Wrocławczyka 42/1U",
      mapUrl: "https://g.page/wkontakcie-wro?share",
    },
    img: wKontakciePhoto,
  },
  {
    type: ['dinner', 'lunch', 'bar'],
    name: "Soczewka",
    description: "Bar, burgers and grill",
    menuUrl: "http://www.soczewka.wroclaw.pl/en",
    location: {
      title: "Rynek 20/21",
      mapUrl: "https://goo.gl/maps/ZY2b9MQSZFAVbDjh9",
    },
    img: soczewkaPhoto,
  },
  {
    type: ['plant cuisine', 'lunch', 'dinner'],
    name: "Falla",
    description: "Vegan and vegetarian cuisine",
    menuUrl: "https://fallawege.pl/",
    location: {
      title: "Stawowa 4",
      mapUrl: "https://goo.gl/maps/4S16UKEfeVPTsJ987",
    },
    img: fallaPhoto,
  },
  {
    type: ['italian food', 'dinner', 'wine', 'lunch', 'bar'],
    name: "VaffaNapoli",
    description: "Neapolitan pizza and pasta",
    menuUrl: "https://www.facebook.com/VaffaWroclaw/",
    location: {
      title: "Włodkowica 13",
      mapUrl: "https://goo.gl/maps/FzKdAYVv9zWtyMpGA",
    },
    img: vaffaNapoliPhoto,
  },
  {
    type: ['asian food', 'bar', 'lunch', 'casual dinner', 'dinner', 'wine'],
    name: "Woosabi",
    description: "Asian fusion kitchen",
    menuUrl: "https://woosabi.pl/",
    location: {
      title: "Świdnicka 28",
      mapUrl: "https://goo.gl/maps/RKFdUSDYcCewY6yMA",
    },
    img: woosabiPhoto,
  },
  {
    type: ['bar', 'lunch', 'casual dinner', 'wine', 'breakfast'],
    name: "Orzo",
    description: "Funky Food & Cocktails",
    menuUrl: 'http://www.orzo.pl/',
    location: {
      title: "Plac Nowy Targ 28",
      mapUrl: "https://goo.gl/maps/wagkJDobMNWah6g37",
    },
    img: orzoPhoto,
  },
  {
    type: ['wine', 'bar', 'lunch'],
    name: "Concordia Taras",
    description: "Snacks, coffee, cocktails and wine",
    menuUrl: 'https://www.concordiadesign.pl/taras/',
    location: {
      title: "Wyspa Słodowa 7",
      mapUrl: "https://goo.gl/maps/qTjiQUdhtQDoVZSSA",
    },
    img: concordiaTarasPhoto,
  },
  {
    type: ['fine dining', 'casual dinner', 'bar', 'wine', 'lunch', 'seasonal food', 'polish food'],
    name: "Młoda Polska bistro & pianino",
    description: 'A new look at Polish flavors',
    menuUrl: 'https://www.mlodapolskabistro.pl/',
    location: {
      title: "Plac Solny 4",
      mapUrl: "https://goo.gl/maps/iPZaFFhzRf9PCKGj6",
    },
    img: mlodaPolskaPhoto,
  },
  {
    type: ['bar', 'wine'],
    name: "Mercado Tapas Bistro",
    description: 'Bar, tapas',
    menuUrl: 'https://www.facebook.com/mercado.wroclaw/',
    location: {
      title: "Wojciecha Bogusławskiego 15",
      mapUrl: "https://g.page/mercado-wroclaw?share",
    },
    img: mercadoTapasPhoto,
  },
  {
    type: ['indian food', 'bar', 'lunch', 'casual dinner', 'plant cuisine'],
    name: "Mango Mama",
    description: 'Indian food',
    menuUrl: 'https://www.mangomama.pl/en',
    location: {
      title: "Św. Mikołaja 18",
      mapUrl: "https://goo.gl/maps/v9K2CXmQeFYUBWpK6",
    },
    img: mangoMamaPhoto,
  },
  {
    type: ['bar', 'dinner', 'lunch', 'casual dinner'],
    name: "Drevny Kocur",
    description: 'Traditional Czech dishes',
    menuUrl: 'https://ovo.drevnykocur.cz/',
    location: {
      title: "Podwale 83",
      mapUrl: "https://goo.gl/maps/3yv5BxUvgFcfiRiLA",
    },
    img: drevnyKocurPhoto,
  },
  {
    type: ["sushi", 'wine', 'lunch', 'casual dinner'],
    name: "Szajnochy 11",
    description: "Sushi",
    menuUrl: "https://szajnochy11.pl/karta_menu/",
    location: {
      title: "Karola Szajnochy 11",
      mapUrl: "https://g.page/Szajnochy11?share",
    },
    img: szajnochy11Photo,
  },
  {
    type: ['fine dining', 'bar', 'wine', 'casual dinner'],
    name: "The Cork",
    description: 'Mediterranean dishes, wine',
    menuUrl: 'https://thecork.pl/',
    location: {
      title: "Igielna 14/15",
      mapUrl: "https://goo.gl/maps/LC4KQ9xqhWE2x3a19",
    },
    img: theCorkPhoto,
  },
  {
    type: ['fine dining', 'casual dinner', 'wine', 'lunch', 'dinner', 'bar'],
    name: "La Maddalena",
    description: 'Restaurant, wine bar',
    menuUrl: 'https://lamaddalena.pl/',
    location: {
      title: "Pomorska 1",
      mapUrl: "https://goo.gl/maps/Q5tpuPiMBmeiDTPA8",
    },
    img: laMaddalenaPhoto,
  },
  {
    type: ['polish food', 'dinner', 'lunch'],
    name: "Pierogarnia Stary Młyn",
    description: 'Polish cuisine, dumplings',
    menuUrl: 'https://www.pierogarnie.com/',
    location: {
      title: "Rynek 26",
      mapUrl: "https://goo.gl/maps/GaCXcSiKcb5qxcdX8",
    },
    img: pierogarniaStaryMlynPhoto,
  },
  {
    type: ['breakfast', 'lunch', 'coffe shop'],
    name: "Etno Cafe",
    description: 'Coffee, desserts, breakfasts, lunches',
    menuUrl: 'https://etnocafe.pl/kawiarnie/',
    location: {
      title: "Podwale 83",
      mapUrl: "https://g.page/etno-cafe-ovo?share",
    },
    img: etnoPhoto,
  },
  {
    type: ['plant cuisine', 'seasonal food', 'lunch', 'dinner'],
    name: "Wilk Syty",
    description: 'Original vegan and vegetarian cuisine',
    menuUrl: 'https://wilksyty.com.pl/',
    location: {
      title: "Trzebnicka 3/1UL",
      mapUrl: "https://goo.gl/maps/iK3nnQ5qR2DnHQJ66",
    },
    img: wilkSytyPhoto,
  },
];
