import jakDojadeAppIcon from "../../assets/img/jakdojade.jpg";
import koleoAppIcon from "../../assets/img/koleo.jpg";
import mPayAppIcon from "../../assets/img/mpay.jpg";
import boltAppIcon from "../../assets/img/bolt.jpg";
import uberAppIcon from "../../assets/img/uber.jpg";
import freenowAppIcon from "../../assets/img/freenow.jpg";
import limeAppIcon from "../../assets/img/lime.jpg";
import tierAppIcon from "../../assets/img/tier.jpg";
import blinkeecityAppIcon from "../../assets/img/blinkeecity.jpg";
import nextbikeAppIcon from "../../assets/img/nextbike.jpg";
import panekAppIcon from "../../assets/img/panek.jpg";
import traficarAppIcon from "../../assets/img/traficar.jpg";
import boltfoodAppIcon from "../../assets/img/boltfood.jpg";
import takeawayAppIcon from "../../assets/img/takeaway.jpg";
import ubereatsAppIcon from "../../assets/img/ubereats.jpg";
import revolutAppIcon from "../../assets/img/revolut.jpg";
import glovoAppIcon from "../../assets/img/glovo.jpg";
import woltAppIcon from "../../assets/img/wolt.jpg";

export const APP_TYPES = ['Public transport', 'Payments', 'Taxi', 'Scooters & Bikes', 'CarSharing', 'Food'] as const;

export interface AppConfig {
  name: string;
  shortDescription?: string;
  img: string;
  iosLink: string;
  androidLink: string;
}

export const PUBLIC_TRANSPORT_APPS: AppConfig[] = [
  {
    name: "Jakdojade",
    shortDescription: "schedules",
    androidLink: "https://play.google.com/store/apps/details?id=com.citynav.jakdojade.pl.android&hl=en&gl=US",
    iosLink: "https://apps.apple.com/pl/app/jakdojade-rozk%C5%82ady-jazdy/id506795511?l=pl",
    img: jakDojadeAppIcon,
  },
  {
    name: "KOLEO",
    shortDescription: "rail travel planner",
    androidLink: "https://play.google.com/store/apps/details?id=pl.koleo&hl=en&gl=US",
    iosLink: "https://apps.apple.com/pl/app/koleo/id1256462727?l=pl",
    img: koleoAppIcon,
  },
  {
    name: "mPay",
    shortDescription: "online tickets",
    androidLink: "https://play.google.com/store/apps/details?id=pl.mpay.app&hl=en&gl=PL",
    iosLink: "https://apps.apple.com/pl/app/mpay-p%C5%82atno%C5%9Bci-mobilne/id969104510?l=pl",
    img: mPayAppIcon,
  },
];

export const PAYMENTS_APPS: AppConfig[] = [
  {
    name: "Revolut",
    androidLink: "https://play.google.com/store/apps/details?id=com.revolut.revolut&hl=en&gl=US",
    iosLink: "https://apps.apple.com/us/app/revolut/id932493382",
    img: revolutAppIcon,
  },
];

export const TAXI_APPS: AppConfig[] = [
  {
    name: "Bolt",
    androidLink: "https://play.google.com/store/apps/details?id=ee.mtakso.client&hl=en&gl=US",
    iosLink: "https://apps.apple.com/pl/app/bolt-przejazdy-hulajnogi/id675033630?l=pl",
    img: boltAppIcon,
  },
  {
    name: "Uber",
    androidLink: "https://play.google.com/store/apps/details?id=com.ubercab&hl=en&gl=US",
    iosLink: "https://apps.apple.com/us/app/uber-request-a-ride/id368677368",
    img: uberAppIcon,
  },
  {
    name: "Free now",
    androidLink: "https://play.google.com/store/apps/details?id=taxi.android.client&hl=en&gl=US",
    iosLink: "https://apps.apple.com/pl/app/free-now-mytaxi/id357852748?l=pl",
    img: freenowAppIcon,
  },
];

export const SCOOTERS_AND_BIKES_APPS: AppConfig[] = [
  {
    name: "Lime",
    androidLink: "https://play.google.com/store/apps/details?id=com.limebike&hl=en&gl=US",
    iosLink: "https://apps.apple.com/pl/app/lime-ridegreen/id1199780189?l=pl",
    img: limeAppIcon,
  },
  {
    name: "Tier",
    androidLink: "https://play.google.com/store/apps/details?id=com.tier.app&hl=en&gl=US",
    iosLink: "https://apps.apple.com/pl/app/tier-hulajnogi-elektryczne/id1436140272?l=pl",
    img: tierAppIcon,
  },
  {
    name: "Blinkee.city",
    androidLink: "https://play.google.com/store/apps/details?id=pl.blinkee.mobile&hl=en&gl=US",
    iosLink: "https://apps.apple.com/pl/app/blinkee-city/id1225700420",
    img: blinkeecityAppIcon,
  },
  {
    name: "Nextbike",
    androidLink: "https://play.google.com/store/apps/details?id=de.nextbike&hl=en&gl=US",
    iosLink: "https://apps.apple.com/pl/app/nextbike/id504288371?l=pl",
    img: nextbikeAppIcon,
  },
];

export const CARSHARING_APPS: AppConfig[] = [
  {
    name: "PANEK CarSharing",
    androidLink: "https://play.google.com/store/apps/details?id=pl.panekcs.mobile&hl=en&gl=US",
    iosLink: "https://apps.apple.com/us/app/panek-cs/id1342734520",
    img: panekAppIcon,
  },
  {
    name: "Traficar",
    androidLink: "https://play.google.com/store/apps/details?id=pl.express.traficar&hl=en&gl=US",
    iosLink: "https://apps.apple.com/us/app/traficar/id1159289063",
    img: traficarAppIcon,
  },
];

export const FOOD_APPS: AppConfig[] = [
  {
    name: "Bolt Food",
    androidLink: "https://play.google.com/store/apps/details?id=com.bolt.deliveryclient&hl=en&gl=US",
    iosLink: "https://apps.apple.com/us/app/bolt-food/id1451492388",
    img: boltfoodAppIcon,
  },
  {
    name: "Glovo",
    androidLink: "https://play.google.com/store/apps/details?id=com.glovo&hl=en&gl=US",
    iosLink: "https://apps.apple.com/pl/app/glovo-dostawa-jedzenie-i-inne/id951812684?l=pl",
    img: glovoAppIcon,
  },
  {
    name: "Wolt",
    androidLink: "https://play.google.com/store/apps/details?id=com.wolt.android&hl=en&gl=US",
    iosLink: "https://apps.apple.com/fi/app/wolt-delivery-food-and-more/id943905271",
    img: woltAppIcon,
  },
  {
    name: "Takeaway.com",
    androidLink: "https://play.google.com/store/apps/details?id=uk.takeaway.android&hl=en&gl=US",
    iosLink: "https://apps.apple.com/us/app/takeaway-com/id333214491",
    img: takeawayAppIcon,
  },
  {
    name: "Uber Eats",
    androidLink: "https://play.google.com/store/apps/details?id=com.ubercab.eats&hl=en&gl=US",
    iosLink: "https://apps.apple.com/pl/app/uber-eats-dostawa-jedzenia/id1058959277?l=pl",
    img: ubereatsAppIcon,
  },
];
