import woodpeckerOfficePhoto from '../../assets/img/woodpecker-office.jpg';
import guestHotelPhoto from '../../assets/img/guest-hotel.jpg';

export interface AddressConfig {
  name: string;
  street: string;
  city: string;
  img: string;
}

export const ADDRESSES: AddressConfig[] = [
  {
    name: 'Woodpecker office',
    street: 'Krakowska 29D Str',
    city: '50-424 Wrocław',
    img: woodpeckerOfficePhoto
  },
  {
    name: 'Your Hotel - B&B Hotels',
    street: 'Piotra Skargi 24/28 Str',
    city: '50-082 Wrocław',
    img: guestHotelPhoto
  }
];