import { PropsWithChildren } from "react";
import { useDesktop } from "../../hooks/useDesktop";

export const DesktopOnly = (props: PropsWithChildren) => {
  const { children } = props;
  const isDesktop = useDesktop();

  if (isDesktop) {
    return <>{children}</>;
  } else {
    return null;
  }
};
