import styled from "styled-components";
import { Container } from "../../components/Container";
import { BackButtonBar } from "../../components/utils/BackButtonBar";
import { ContentContainer } from "../../components/utils/ContentContainer";
import { DesktopOnly } from "../../components/utils/DesktopOnly";
import { H4Semibold } from "../../components/utils/H4Semibold";
import { MobileOnly } from "../../components/utils/MobileOnly";
import { BREAKPOINTS } from "../../constants/styles";
import { ADDRESSES, AddressConfig } from "./addresses.config";

interface AddressProps extends BaseStyledComponentProps {
  address: AddressConfig;
}

const UnstyledAddress = (props: AddressProps) => {
  const {
    address: { name, street, city, img },
    className,
  } = props;

  return (
    <address className={className}>
      <h2>{name}</h2>
      <p>{street}</p>
      <p>{city}</p>
      <img src={img} alt={name} />
    </address>
  );
};

const Address = styled(UnstyledAddress)`
  display: flex;
  flex-direction: column;
  h2 {
    font-size: 16px;
    line-height: 24px;
    font-family: "PoppinsMedium", sans-serif;
    font-weight: 500;
    margin-bottom: 20px;
  }

  p {
    font-size: 14px;
    line-height: 22px;
  }

  img {
    margin-top: 16px;
    border-radius: 5px;
  }
`;

interface AddressesProps extends BaseStyledComponentProps {}

export const UnstyledAddresses = (props: AddressesProps) => {
  const { className } = props;

  return (
    <ContentContainer>
      <div className={className}>
        <MobileOnly>
          <BackButtonBar />
        </MobileOnly>

        <Container>
          <DesktopOnly>
            <H4Semibold>Adresses</H4Semibold>
          </DesktopOnly>
          <ul>
            {ADDRESSES.map((address) => (
              <li key={address.name}>
                <Address address={address} />
              </li>
            ))}
          </ul>
        </Container>
      </div>
    </ContentContainer>
  );
};

export const Addresses = styled(UnstyledAddresses)`
  ul {
    margin-top: 10px;
    @media ${BREAKPOINTS.DESKTOP} {
      margin-top: 40px;
      display: grid;
      grid-template-columns: repeat(3, 312px);
      justify-content: space-between;
      grid-gap: 48px 40px;
    }

    li {
      &:not(:first-child) {
        margin-top: 37px;
        @media ${BREAKPOINTS.DESKTOP} {
          margin-top: 0;
        }
      }
    }
  }
`;
