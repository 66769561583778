interface ConditionalWrapperProps {
  shouldWrap: boolean;
  wrapper: (children: React.ReactNode) => JSX.Element;
  children: React.ReactNode;
}

export const ConditionalWrapper = (props: ConditionalWrapperProps) => {
  const { shouldWrap, wrapper, children } = props;

  if (shouldWrap) {
    return wrapper(children);
  } else {
    return <>{children}</>;
  }
};
