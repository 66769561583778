export const DAY_CONFIG = [
  {
    isActive: true,
    to: `/days/1`,
    date: "12 Sep",
    dayOfWeek: "Mon",
  },
  {
    isActive: false,
    to: "/days/2",
    date: "13 Sep",
    dayOfWeek: "Tue",
  },
  {
    isActive: false,
    to: "/days/3",
    date: "14 Sep",
    dayOfWeek: "Wed",
  },
  {
    isActive: false,
    to: "/days/4",
    date: "15 Sep",
    dayOfWeek: "Thu",
  },
  {
    isActive: false,
    to: "/days/5",
    date: "16 Sep",
    dayOfWeek: "Fri",
  },
]