import { PropsWithChildren, useEffect, useMemo, useRef, useState } from "react";
import styled, { createGlobalStyle, css } from "styled-components";
import kebabCase from "just-kebab-case";
import debounce from "just-debounce-it";
import MobileDetect from "mobile-detect";
import { Controller } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Element, Link } from "react-scroll";
import { Tag } from "../../components/Tag";
import { BackButtonBar } from "../../components/utils/BackButtonBar";
import {
  AppConfig,
  APP_TYPES,
  PUBLIC_TRANSPORT_APPS,
  TAXI_APPS,
  CARSHARING_APPS,
  FOOD_APPS,
  SCOOTERS_AND_BIKES_APPS,
  PAYMENTS_APPS,
} from "./apps.config";
import { BREAKPOINTS } from "../../constants/styles";
import { VeryNarrowContainer } from "../../components/VeryNarrowContainer";
import { ContentContainer } from "../../components/utils/ContentContainer";
import { MobileOnly } from "../../components/utils/MobileOnly";
import { DesktopOnly } from "../../components/utils/DesktopOnly";
import { H4Semibold } from "../../components/utils/H4Semibold";

const md = new MobileDetect(window.navigator.userAgent);

interface AppsBlockProps {
  noMargin?: boolean;
}

const AppsBlock = styled.article.attrs((props: AppsBlockProps) => ({
  noMargin: props.noMargin || false,
}))`
  padding-top: ${(props) => (props.noMargin ? 0 : "40px")};
  @media ${BREAKPOINTS.DESKTOP} {
    padding-top: 0;
  }
`;

const AppsHeading = styled.h2`
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  font-family: "PoppinsSemiBold", sans-serif;
  margin-bottom: 16px;
`;

interface AppProps extends BaseStyledComponentProps {
  app: AppConfig;
}

const isApple = md.is("iOS") || md.is("iPadOS");
const isAndroid = md.is("AndroidOS");

const UnstyledApp = (props: AppProps) => {
  const {
    app: { androidLink, img, iosLink, name, shortDescription },
    className,
  } = props;

  return (
    <article className={className}>
      <div className="img-container">
        <img src={img} alt={`${name} logo`} />
      </div>
      <div className="info">
        <h3>{name}</h3>
        {shortDescription && <span>({shortDescription})</span>}
      </div>

      <div className="links">
        {isApple && (
          <a href={iosLink} target="_blank" rel="noreferrer">
            Download
          </a>
        )}
        {(isAndroid || !isApple) && (
          <a href={androidLink} target="_blank" rel="noreferrer">
            Download
          </a>
        )}
      </div>
    </article>
  );
};

const App = styled(UnstyledApp)`
  display: grid;
  grid-gap: 12px 24px;
  grid-template-columns: 104px 1fr;
  grid-template-rows: 1fr 1fr;
  height: 104px;
  filter: drop-shadow(-4px 4px 16px rgba(91, 124, 85, 0.1))
    drop-shadow(4px -4px 16px rgba(91, 124, 85, 0.1));
  .img-container {
    width: 104px;
    height: 104px;
    border-radius: 10px;
    overflow: hidden;
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .info {
    font-size: 14px;
    line-height: 24px;
    grid-column: 2 / 3;
    display: flex;
    align-self: flex-end;
    flex-direction: column;
    h3 {
      font-weight: 600;
      font-family: "PoppinsSemiBold", sans-serif;
    }
  }

  .links {
    grid-column: 2 / 3;
    align-self: flex-start;
    a {
      font-size: 14px;
      line-height: 18px;
      &,
      &:link,
      &:visited,
      &:hover,
      &:active {
        font-weight: 500;
        font-family: "PoppinsMedium", sans-serif;
        text-decoration: underline;
        color: #000000;
      }
    }
  }
`;

interface AppsListProps extends BaseStyledComponentProps {
  apps: AppConfig[];
}

const UnstyledAppsList = (props: AppsListProps) => {
  const { className, apps } = props;

  return (
    <ul className={className}>
      {apps.map((app) => (
        <li key={app.name}>
          <App app={app} />
        </li>
      ))}
    </ul>
  );
};

const AppList = styled(UnstyledAppsList).attrs(
  (props: { $isDesktopGrid: boolean }) => ({
    $isDesktopGrid: props.$isDesktopGrid || false,
  })
)`
  ${(props) => {
    if (props.$isDesktopGrid) {
      return css`
        @media ${BREAKPOINTS.DESKTOP} {
          display: grid;
          grid-template-columns: repeat(auto-fill, 316px);
          justify-content: space-between;
          grid-gap: 16px 40px;
        }
      `;
    } else {
      return css``;
    }
  }}

  li {
    &:not(:last-child) {
      margin-bottom: 16px;
      @media ${BREAKPOINTS.DESKTOP} {
        margin-bottom: ${(props) => (props.$isDesktopGrid ? 0 : "16px")};
      }
    }
  }
`;

interface NavLinkProps extends BaseStyledComponentProps {
  isActive?: boolean;
  to: string;
  onSetActive?: () => void;
}

const UnstyledNavLink = (props: PropsWithChildren<NavLinkProps>) => {
  const { isActive = false, onSetActive, children, to, className } = props;

  return (
    <Link
      className={className}
      to={to}
      offset={-64}
      spy={true}
      hashSpy={true}
      onSetActive={onSetActive}
    >
      <Tag tag="span" active={isActive}>
        {children}
      </Tag>
    </Link>
  );
};

const NavLink = styled(UnstyledNavLink)`
  ${Tag} {
    cursor: pointer;
  }
`;

interface AppsProps extends BaseStyledComponentProps {}

type AppType = typeof APP_TYPES[number];

const APPS_CONFIG: {
  apps: AppConfig[];
  type: AppType;
}[] = [
  {
    apps: PUBLIC_TRANSPORT_APPS,
    type: "Public transport",
  },
  {
    apps: PAYMENTS_APPS,
    type: "Payments",
  },
  {
    apps: TAXI_APPS,
    type: "Taxi",
  },
  {
    apps: SCOOTERS_AND_BIKES_APPS,
    type: "Scooters & Bikes",
  },
  {
    apps: CARSHARING_APPS,
    type: "CarSharing",
  },
  {
    apps: FOOD_APPS,
    type: "Food",
  },
];

const SwiperSlideOverloadsStyles = createGlobalStyle`
  .public-transport {
    width: 145px;
  }

  .payments {
    width: 104px;
  }
  
  .taxi {
    width: 62px;
  }
  
  .scooters-bikes {
    width: 148px;
  }
  
  .car-sharing {
    width: 114px;
  }
  
  .food {
    width: 114px;
  }

  .swiper {
    padding: 16px 0;
    overflow: visible;
  }
`;

const isAppListDesktopGrid = (type: AppType) =>
  type === "Food" || type === "Scooters & Bikes" || type === "Taxi";

const UnstyledApps = (props: AppsProps) => {
  const { className } = props;
  const [currentAppType, setCurrentAppType] = useState<AppType>();
  // @ts-ignore
  const [controlledSwiper, setControlledSwiper] = useState<Swiper | null>(null);
  const slideTo = useMemo(
    () =>
      debounce((slideIndex: number) => {
        controlledSwiper.slideTo(slideIndex);
      }, 200),
    [controlledSwiper]
  );
  useEffect(() => {
    if (currentAppType) {
      const currentIndex = APP_TYPES.indexOf(currentAppType);
      slideTo(currentIndex);
    }
  }, [currentAppType, controlledSwiper, slideTo]);
  //
  const [isSticked, setSticked] = useState(false);
  const barRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (barRef.current) {
      const fn = () => {
        if (barRef.current) {
          const { top } = barRef.current?.getBoundingClientRect();
          if (top < 0) {
            setSticked(true);
          } else {
            setSticked(false);
          }
        }
      };

      window.addEventListener("scroll", fn);

      return () => {
        window.removeEventListener("scroll", fn);
      };
    }
  }, [barRef.current]);

  return (
    <ContentContainer>
      <div className={className}>
        <MobileOnly>
          <BackButtonBar />
          <SwiperSlideOverloadsStyles />

          <div className="app-types-container" ref={barRef}>
            <div
              style={{
                position: isSticked ? "fixed" : undefined,
              }}
              className="app-types-container-types"
            >
              <VeryNarrowContainer>
                <Swiper
                  spaceBetween={16}
                  slidesPerView="auto"
                  modules={[Controller]}
                  onSwiper={setControlledSwiper}
                >
                  {APP_TYPES.map((type) => (
                    <SwiperSlide className={kebabCase(type)} key={type}>
                      <NavLink
                        to={kebabCase(type)}
                        isActive={type === currentAppType}
                        onSetActive={() => setCurrentAppType(type)}
                      >
                        {type}
                      </NavLink>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </VeryNarrowContainer>
            </div>
            <div className="app-types-container-height-compensator"></div>
          </div>
        </MobileOnly>

        <VeryNarrowContainer>
          <DesktopOnly>
            <H4Semibold>Useful apps</H4Semibold>
          </DesktopOnly>
          <div className="apps-list-container">
            {APPS_CONFIG.map((appsCfg, i, arr) => (
              <div
                className={`${kebabCase(appsCfg.type)}-apps-container`}
                key={appsCfg.type}
              >
                <Element name={kebabCase(appsCfg.type)}>
                  <AppsBlock noMargin={i === 0}>
                    <AppsHeading>{appsCfg.type}</AppsHeading>
                    <AppList
                      apps={appsCfg.apps}
                      $isDesktopGrid={isAppListDesktopGrid(appsCfg.type)}
                    />
                  </AppsBlock>
                </Element>
              </div>
            ))}
          </div>
        </VeryNarrowContainer>
      </div>
    </ContentContainer>
  );
};

export const Apps = styled(UnstyledApps)`
  .app-types-container {
    z-index: 1;
    position: relative;
    &-height-compensator {
      height: 64px;
    }

    &-types {
      position: absolute;
      top: 0;
      width: 100%;
      background-color: #fff;
    }
  }

  .public-transport-apps-container {
    grid-area: public-transport;
  }

  .payments-apps-container {
    grid-area: payments;
  }

  .taxi-apps-container {
    grid-area: taxi;
  }

  .scooters-bikes-apps-container {
    grid-area: scooters-bikes;
  }

  .car-sharing-apps-container {
    grid-area: car-sharing;
  }

  .food-apps-container {
    grid-area: food;
  }

  .apps-list-container {
    margin-top: 20px;
    position: relative;
    z-index: 0;
    @media ${BREAKPOINTS.DESKTOP} {
      display: grid;
      grid-template-columns: repeat(3, 312px);
      justify-content: space-between;
      grid-gap: 48px 40px;
      grid-template-areas:
        "public-transport food food"
        "scooters-bikes scooters-bikes payments"
        "car-sharing taxi taxi";
      margin-top: 48px;
    }
  }
`;
