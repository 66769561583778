export interface EmergencyNumberConfig {
  text: string;
  number: string;
}

export const EMERGENCY_NUMBERS_CONFIG: EmergencyNumberConfig[] = [
  {
    text: 'General emergencies',
    number: '112'
  },
  {
    text: 'Ambulance',
    number: '999'
  },
  {
    text: 'Fire brigade',
    number: '998'
  },
  {
    text: 'Police',
    number: '997'
  },
  {
    text: 'Road assistance',
    number: '981'
  },
]