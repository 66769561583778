import { AnimatePresence } from "framer-motion";
import { Route, Routes, useLocation } from "react-router-dom";
import { AnimationContainer } from "../../components/utils/AnimationContainer";
import { Calendar } from "./Calendar";
import {
  DesktopEventList,
  MobileEventList,
} from "./event-list/EventList.route";
import { DAY_CONFIG } from "./day.config";
import { ContentContainer } from "../../components/utils/ContentContainer";
import { MobileOnly } from "../../components/utils/MobileOnly";
import { DesktopOnly } from "../../components/utils/DesktopOnly";
import { FloatingContactButton } from "../../components/FloatingContactButton";
import { useDesktop } from "../../hooks/useDesktop";

interface HomeProps {}

export const Home = (props: HomeProps) => {
  const location = useLocation();
  const isDesktop = useDesktop();

  return (
    <>
      <AnimatePresence mode="wait" initial={false}>
        <Routes location={location} key={location.pathname}>
          <Route
            path="/days/:day"
            element={
              <ContentContainer bgColor={isDesktop ? "#F8FDF8" : undefined}>
                <MobileOnly>
                  <Calendar days={DAY_CONFIG} />
                  <AnimationContainer>
                    <MobileEventList />
                  </AnimationContainer>
                </MobileOnly>

                <DesktopOnly>
                  <AnimationContainer>
                    <DesktopEventList />
                  </AnimationContainer>
                </DesktopOnly>

                <MobileOnly>
                  <FloatingContactButton />
                </MobileOnly>
              </ContentContainer>
            }
          />
        </Routes>
      </AnimatePresence>
    </>
  );
};
