import styled from "styled-components";
import { BREAKPOINTS } from "../../constants/styles";

export const ContentContainer = styled.main.attrs(
  (props: { bgColor: string }) => ({
    bgColor: props.bgColor || "",
  })
)`
  background-color: ${(props) => props.bgColor};
  min-height: calc(100 * var(--vh, 1vh) - 83px);
  padding-bottom: 30px;
  @media ${BREAKPOINTS.DESKTOP} {
    padding-top: 48px;
    min-height: 0;
  }
`;
