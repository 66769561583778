export interface EventConfig {
  type: "workshop" | "studio" | "cafe" | "food" | "outdoor" | "studio_outdoor";
  text: string;
  time: string;
  auxLocation?: string;
}

export const DAY_1_EVENT_LIST_CONFIG: EventConfig[] = [
  {
    type: "workshop",
    text: "Welcome session: who's who and what's going to happen (Mike - host of Cold Email Camp, Jared Burke)",
    auxLocation: "Ground floor",
    time: "09:00-09:30",
  },
  {
    type: "studio",
    text: "Morning interviews for the documentary",
    time: "09:30-11:30",
    auxLocation: "1st floor",
  },
  {
    type: "workshop",
    text: "Morning session: Matt Tarczyński",
    auxLocation: "Ground floor",
    time: "11:45-12:30",
  },
  {
    type: "cafe",
    text: "Coffee break",
    time: "12:30-13:00",
    auxLocation: "Ground floor",
  },
  {
    type: "workshop",
    text: "Main workshop: Jared Burke - Targeting+Audiences pt 1",
    auxLocation: "Ground floor",
    time: "13:00-13:45",
  },
  {
    type: "workshop",
    text: "Main workshop: Jared Burke - Targeting+Audiences pt 2",
    auxLocation: "Ground floor",
    time: "14:00-14:45",
  },
  {
    type: "food",
    text: "Lunch break",
    auxLocation: "1st floor",
    time: "14:45-15:30",
  },
  {
    type: "workshop",
    text: "Evening session: Margaret - Warm-up",
    auxLocation: "Ground floor",
    time: "15:30-17:00",
  },
  {
    type: "studio",
    text: "Evening interview",
    time: "17:15-18:15",
    auxLocation: "1st floor",
  },
];

export const DAY_2_EVENT_LIST_CONFIG: EventConfig[] = [
  {
    type: "workshop",
    text: "AMA about sales calls with Nick Cegelski",
    time: "09:00-10:00",
    auxLocation: "Ground floor",
  },
  {
    type: "workshop",
    text: "Morning session pt 1: Jared Burke + Woodpecker CS team - Technical set-up",
    auxLocation: "Ground floor",
    time: "10:15-11:15",
  },
  {
    type: "cafe",
    text: "Coffee break",
    time: "11:15-11:30",
    auxLocation: "Ground floor",
  },
  {
    type: "studio",
    text: "Morning interview",
    time: "11:30-12:00",
    auxLocation: "1st floor",
  },
  {
    type: "workshop",
    text: "Main workshop: Jared Burke - Prospecting+Data pt 1",
    auxLocation: "Ground floor",
    time: "12:00-13:00",
  },
  {
    type: "workshop",
    text: "Lusha demo",
    auxLocation: "Ground floor",
    time: "12:30-13:00",
  },
  {
    type: "workshop",
    text: "Main workshop: Jared Burke - Prospecting+Data pt 2",
    auxLocation: "Ground floor",
    time: "13:15-14:00",
  },
  {
    type: "food",
    text: "Lunch break",
    auxLocation: "1st floor",
    time: "14:00-14:45",
  },
  {
    type: "workshop",
    text: "Evening session: Emilia Mosiewicz - Lean Sales workshop pt 1",
    auxLocation: "Ground floor",
    time: "14:45-16:15",
  },
  {
    type: 'outdoor',
    text: "Surprise",
    time: "17:30-20:30",
  },
  {
    type: "studio_outdoor",
    text: "Evening interview",
    time: "18:00-19:00",
  },
];

export const DAY_3_EVENT_LIST_CONFIG: EventConfig[] = [
  {
    type: "studio",
    text: "Morning interview",
    time: "09:00-09:45",
    auxLocation: "1st floor",
  },
  {
    type: "workshop",
    text: "Morning session: Emilia Mosiewicz - Lean Sales workshop pt 2",
    auxLocation: "Ground floor",
    time: "10:00-11:30",
  },
  {
    type: "cafe",
    text: "Coffee break",
    time: "11:30-12:00",
    auxLocation: "Ground floor",
  },
  {
    type: "workshop",
    text: "Main workshop: Jared Burke - Messaging pt 1",
    auxLocation: "Ground floor",
    time: "12:00-12:45",
  },
  {
    type: "workshop",
    text: "Main workshop: Jared Burke - Messaging pt 2",
    auxLocation: "Ground floor",
    time: "13:00-13:45",
  },
  {
    type: "food",
    text: "Lunch break",
    auxLocation: "1st floor",
    time: "13:45-14:30",
  },
  {
    type: "workshop",
    text: "Networking session",
    auxLocation: "Ground floor",
    time: "15:15-16:30",
  },
  {
    type: "workshop",
    text: "Virtual session: Jason Bay - Drive replies with follow-ups",
    auxLocation: "Ground floor",
    time: "17:00-18:30",
  },
  {
    type: "studio",
    text: "Evening interview",
    time: "18:30-19:30",
    auxLocation: "1st floor",
  },
]

export const DAY_4_EVENT_LIST_CONFIG: EventConfig[] = [
  {
    type: "studio",
    text: "Morning interview",
    time: "09:00-10:00",
    auxLocation: "1st floor",
  },
  {
    type: "workshop",
    text: "Morning workshop: Jared Burke - Automation",
    auxLocation: "Ground floor",
    time: "10:15-10:45",
  },
  {
    type: "cafe",
    text: "Coffee break",
    time: "11:00-11:30",
    auxLocation: "Ground floor",
  },
  {
    type: "workshop",
    text: "Main workshop: Jared Burke & Woodpecker CS/Support team - Campaign setup pt 1",
    auxLocation: "Ground floor",
    time: "11:30-12:15",
  },
  {
    type: "workshop",
    text: "Main workshop: Jared Burke & Woodpecker CS/Support team - Campaign setup pt 2",
    auxLocation: "Ground floor",
    time: "12:30-13:15",
  },
  {
    type: "food",
    text: "Lunch break",
    auxLocation: "1st floor",
    time: "13:45-14:30",
  },
  {
    type: "workshop",
    text: "Virtual session: Patrick Baynes - Setting sales targets",
    auxLocation: "Ground floor",
    time: "14:30-15:45",
  },
  {
    type: "workshop",
    text: "Virtual session: Josh Braun - Fewer rejections. More conversations",
    auxLocation: "Ground floor",
    time: "16:00-17:30",
  },
  {
    type: "studio",
    text: "Evening interview",
    time: "17:30-18:30",
    auxLocation: "1st floor",
  },
]

export const DAY_5_EVENT_LIST_CONFIG: EventConfig[] = [
  {
    type: "studio",
    text: "Morning interview",
    time: "09:00-09:45",
    auxLocation: "1st floor",
  },
  {
    type: "workshop",
    text: "Morning session: Margaret Sikora + Jared Burke: GDPR",
    auxLocation: "Ground floor",
    time: "10:00-11:30",
  },
  {
    type: "cafe",
    text: "Coffee break",
    time: "11:30-12:00",
    auxLocation: "Ground floor",
  },
  {
    type: "workshop",
    text: "Main workshop: Jared Burke - Next steps for your outreach pt 1",
    auxLocation: "Ground floor",
    time: "12:00-12:45",
  },
  {
    type: "workshop",
    text: "Main workshop: Jared Burke - Next steps for your outreach pt 2",
    auxLocation: "Ground floor",
    time: "13:00-14:00",
  },
  {
    type: "food",
    text: "Lunch break",
    auxLocation: "1st floor",
    time: "14:00-14:45",
  },
  {
    type: "workshop",
    text: "Q&A + closing session: Jared Burke, Mike",
    auxLocation: "Ground floor",
    time: "14:45-15:45",
  },
  {
    type: "studio",
    text: "Evening interview",
    time: "16:00-18:00",
    auxLocation: "1st floor",
  },
  {
    type: "workshop",
    text: "Afterparty",
    time: "18:00-23:45",
    auxLocation: "Ground floor",
  },
]