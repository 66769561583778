import styled, { css } from "styled-components";

interface HamburgerProps extends BaseStyledComponentProps {
  isActive?: boolean;
  onClick?: React.MouseEventHandler;
}

const UnstyledHamburgerButton = (props: HamburgerProps) => {
  const { className, onClick } = props;

  return (
    <button className={className} onClick={onClick}>
      <span></span>
      <span></span>
      <span></span>
    </button>
  );
};

export const HamburgerButton = styled(UnstyledHamburgerButton)`
  width: 28px;
  height: 20px;
  position: relative;
  cursor: pointer;
  span {
    opacity: 1;
    transition: 0s 0.1s opacity cubic-bezier(0.55, 0.055, 0.675, 0.19);
    height: 2px;
    width: 100%;
    background-color: #000;
    position: absolute;
    left: 0;
    &:first-child,
    &:last-child {
      transition: 0.1s 0.1s all cubic-bezier(0.55, 0.055, 0.675, 0.19),
        0.1s transform cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    &:first-child {
      top: 0;
    }

    &:nth-child(2) {
      top: 50%;
      transform: translateY(-50%);
    }

    &:last-child {
      bottom: 0;
    }
  }

  ${(props) => {
    if (props.isActive) {
      return css`
        span {
          &:first-child,
          &:last-child {
            transition: 0.1s all cubic-bezier(0.55, 0.055, 0.675, 0.19),
              0.1s 0.1s transform cubic-bezier(0.55, 0.055, 0.675, 0.19);
          }

          &:first-child {
            top: 9px;
            transform: rotate(45deg);
          }

          &:nth-child(2) {
            opacity: 0;
          }

          &:last-child {
            bottom: 9px;
            transform: rotate(-45deg);
          }
        }
      `;
    }
  }}
`;
