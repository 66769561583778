import styled from "styled-components";
import { Link } from "react-router-dom";
import { Container } from "./Container";
import { HamburgerButton } from "./utils/HamburgerButton";
import { WoodpeckerColdEmailCampLogo } from "./WoodpeckerCampLogo";
import { MobileOnly } from "./utils/MobileOnly";
import { BREAKPOINTS, SUPPORTS_HOVER } from "../constants/styles";
import { DesktopOnly } from "./utils/DesktopOnly";
import { NavItemConfig, NAV_CONFIG } from "../constants/nav";
import { useEffect, useState } from "react";
import { useDesktop } from "../hooks/useDesktop";

interface HeaderNavProps extends BaseStyledComponentProps {
  navConfig: NavItemConfig[];
}

const UnstyledHeaderNav = (props: HeaderNavProps) => {
  const { navConfig, className } = props;

  return (
    <nav className={className}>
      <ul>
        {navConfig.map((item) => (
          <li key={item.title}>
            <Link to={item.href} className="link">
              {item.title}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

const HeaderNav = styled(UnstyledHeaderNav)`
  ul {
    display: flex;
    li {
      &:not(:last-child) {
        margin-right: 30px;
      }
    }
  }

  .link {
    font-family: "PoppinsMedium", sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    transition: 0.1s ease-in-out color;
    &,
    &:link,
    &:visited,
    &:hover,
    &:active {
      color: #0b0204;
      text-decoration: none;
    }

    @media ${SUPPORTS_HOVER} {
      &:hover {
        color: #c0c1c6;
      }
    }
  }
`;

interface HeaderProps {
  className?: string;
  isMobileNavOpened?: boolean;
  onMobileNavToggle?: () => void;
}

const DESKTOP_NAV_CONFIG: NavItemConfig[] = [
  {
    href: "/",
    title: "Calendar",
  },
  ...NAV_CONFIG,
];

const UnstyledHeader = (props: HeaderProps) => {
  const { className, isMobileNavOpened, onMobileNavToggle } = props;
  const isDesktop = useDesktop();
  const [navConfig, setNavConfig] = useState<NavItemConfig[]>(
    isDesktop ? DESKTOP_NAV_CONFIG : NAV_CONFIG
  );
  useEffect(() => {
    setNavConfig(isDesktop ? DESKTOP_NAV_CONFIG : NAV_CONFIG);
  }, [isDesktop]);

  return (
    <header className={className}>
      <Container>
        <div className="header-container">
          <Link to="/" className="link">
            <WoodpeckerColdEmailCampLogo />
          </Link>

          <DesktopOnly>
            <HeaderNav navConfig={navConfig} />
          </DesktopOnly>

          <MobileOnly>
            <HamburgerButton
              onClick={onMobileNavToggle}
              isActive={isMobileNavOpened}
            />
          </MobileOnly>
        </div>
      </Container>
    </header>
  );
};

export const Header = styled(UnstyledHeader)`
  display: flex;
  height: 83px;
  align-items: center;
  @media ${BREAKPOINTS.DESKTOP} {
    height: 88px;
    box-shadow: 0px 2px 4px rgba(161, 169, 197, 0.2);
  }

  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .link {
    &,
    &:link,
    &:visited,
    &:hover,
    &:active {
      text-decoration: none;
    }
  }
`;
