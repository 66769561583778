import { useMemo } from "react";
import { eachDayOfInterval, isSameDay, isWithinInterval } from "date-fns";
const CAMP_START = new Date("2022-09-12");
const CAMP_END = new Date("2022-09-17");

export const useCampDay = () => {
  const campDay = useMemo(() => {
    const now = new Date();
    const campInterval = {
      start: CAMP_START,
      end: CAMP_END,
    };

    if (isWithinInterval(now, campInterval)) {
      const intervalDays = eachDayOfInterval(campInterval);
      let dayIndex = 1;
      for (let index = 0; index < intervalDays.length; index++) {
        const day = intervalDays[index];
        if (isSameDay(now, day)) {
          dayIndex = index + 1;
        }
      }

      return dayIndex;
    } else {
      return 1;
    }
  }, []);

  return campDay;
};
