import { ReactComponent as MapIcon28x28 } from "../assets/icons/map-28x28.svg";
import { ReactComponent as LocationIcon28x28 } from "../assets/icons/location-28x28.svg";
import { ReactComponent as BookIcon28x28 } from "../assets/icons/book-28x28.svg";
import { ReactComponent as PhoneIcon28x28 } from "../assets/icons/phone-28x28.svg";
import { ReactComponent as DownloadIcon28x28 } from "../assets/icons/download-28x28.svg";

export interface NavItemConfig {
  icon?: React.ComponentType;
  href: string;
  title: string;
}

export const MAP_HREF = '/office-map';
export const ADDRESSES_HREF = '/addresses';
export const RESTAURANTS_HREF = '/restaurants';
export const NUMBERS_HREF = '/numbers';
export const APPS_HREF = '/apps';

export const NAV_CONFIG: NavItemConfig[] = [
  {
    icon: MapIcon28x28,
    title: 'Office plan',
    href: MAP_HREF
  },
  {
    icon: LocationIcon28x28,
    title: 'Adresses',
    href: ADDRESSES_HREF,
  },
  {
    title: 'Restaurant Guide',
    href: RESTAURANTS_HREF,
    icon: BookIcon28x28
  },
  {
    title: 'Phone numbers',
    href: NUMBERS_HREF,
    icon: PhoneIcon28x28
  },
  {
    title: 'Useful apps',
    href: APPS_HREF,
    icon: DownloadIcon28x28
  },
]