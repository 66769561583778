import styled from "styled-components";
import { BREAKPOINTS } from "../../constants/styles";

export const H4Semibold = styled.h4`
  font-family: "PoppinsSemibold";
  font-weight: 600;

  @media ${BREAKPOINTS.DESKTOP} {
    font-size: 36px;
    line-height: 48px;
  }
`;
