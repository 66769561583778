import styled from "styled-components";
import { Link } from "react-router-dom";
import { ReactComponent as PhoneIcon36x36 } from "../assets/icons/phone-36x36.svg";

interface Props {
  className?: string;
}

const UnstyledFloatingContactButton = (props: Props) => {
  const { className } = props;

  return (
    <Link to="/numbers/" className={className}>
      <PhoneIcon36x36 />
    </Link>
  );
};

export const FloatingContactButton = styled(UnstyledFloatingContactButton)`
  position: fixed;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 30px;
  right: 30px;
`;
