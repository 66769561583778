import anastassiiaPhoto from '../../assets/img/anastasiia-kosianchuk.jpg';
import bbHotelLogo from '../../assets/img/bb-hotels.jpg';
import justynaWaciegaPhoto from '../../assets/img/justyna-waciega.jpg';
import agataZborowskaKrzyzokPhoto from '../../assets/img/agata-zborowska-krzyzok.jpg';
import tomixPhoto from '../../assets/img/tomix-rudowicz.jpg';
import joannaPodolskaPhoto from '../../assets/img/joanna-podolska.jpg';

export interface ContactInfoConfig {
  name: string;
  number: string;
  img: string;
  position?: string;
}

export const LOGISTICS_ORGANIZATIONAL_CONTACT_INFO: ContactInfoConfig = {
  name: 'Anastasiia',
  position: 'Executive Assistant',
  number: '+48 510 177 716',
  img: anastassiiaPhoto
}

export const HOTEL_CONTACT_INFO: ContactInfoConfig = {
  name: 'Reception',
  number: '+48 71 324 09 80',
  img: bbHotelLogo
}

export const OTHER_PROBLEMS_CONTACT_INFOS: ContactInfoConfig[] = [
  {
    name: 'Justyna',
    position: 'Junior Marketing Specialist',
    number: '+48 572 224 230',
    img: justynaWaciegaPhoto
  },
  {
    name: 'Agata',
    position: 'Marketing Project Manager',
    number: '+48 606 338 578',
    img: agataZborowskaKrzyzokPhoto
  },
  {
    name: 'Tomix',
    position: 'Lead Web-Developer (the driver)',
    number: '+48 667 703 203',
    img: tomixPhoto
  },
  {
    name: 'Joanna',
    position: 'Head of Administration (office)',
    number: '+48 784 446 210',
    img: joannaPodolskaPhoto
  },
]