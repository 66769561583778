import "./App.scss";
import { BrowserRouter } from "react-router-dom";
import { AppRoutes } from "./AppRoutes";
import { HeaderWithMobileNav } from "./components/HeaderWithMobileNav";
import { OverflowContainer } from "./components/utils/OverflowContainer";

function App() {
  return (
    <BrowserRouter>
      <OverflowContainer>
        <HeaderWithMobileNav />
        <AppRoutes />
      </OverflowContainer>
    </BrowserRouter>
  );
}

export default App;
