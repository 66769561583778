import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import whatsappLogoImg from "../assets/img/whatsapp.jpg";
import { useEffect } from "react";
import { VeryNarrowContainer } from "./VeryNarrowContainer";
import { NAV_CONFIG } from "../constants/nav";

interface MobileNavProps extends BaseStyledComponentProps {
  isOpened: boolean;
}

const UnstyledMobileNav = (props: MobileNavProps) => {
  const { className, isOpened } = props;
  useEffect(() => {
    if (isOpened) {
      disableBodyScroll(document.body);
    } else {
      enableBodyScroll(document.body);
    }
  }, [isOpened]);

  return (
    <nav className={className}>
      <aside className="participants-welcome">
        <VeryNarrowContainer>
          <p>👋 Hello guys!</p>
          <p className="participants-welcome-day-welcome">
            Isn't it just the perfect day to send cold emails? Have fun today!
          </p>
        </VeryNarrowContainer>
      </aside>

      <div className="nav">
        <VeryNarrowContainer>
          <ul className="link-list">
            {NAV_CONFIG.map((item) => (
              <li key={item.title}>
                <Link to={item.href}>
                  {item.icon && <item.icon />}
                  {item.title}
                </Link>
              </li>
            ))}
          </ul>
        </VeryNarrowContainer>
      </div>

      <aside className="camp-channel">
        <VeryNarrowContainer>
          <h2>
            <p>Join our private Cold Email Camp group on</p>
          </h2>
          <div className="camp-channel-vendor-container">
            <a
              href="https://chat.whatsapp.com/JCSJ1vMONwq6hVucbnPL2p"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={whatsappLogoImg}
                alt="Whatsapp icon"
                className="whatsapp-logo"
              />
              Whatsapp
            </a>
          </div>
        </VeryNarrowContainer>
      </aside>
    </nav>
  );
};

export const MobileNav = styled(UnstyledMobileNav)`
  padding-top: calc(83px + 12px);
  height: calc(100 * var(--vh, 1vh));
  width: calc(100 * var(--vv, 1vw));
  will-change: transform;
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  top: 0;
  transition: 0.2s ease-in-out transform;
  transform: ${(props) =>
    props.isOpened ? "translateX(0)" : "translateX(-100%)"};
  transition: 0.2s ease-in-out transform;

  .whatsapp-logo {
    width: 24px;
    border-radius: 2px;
    box-shadow: -4px 4px 16px rgba(91, 124, 85, 0.1),
      4px -4px 16px rgba(91, 124, 85, 0.1);
  }

  .nav {
    flex-grow: 1;
    padding-top: 40px;
  }

  .participants-welcome {
    font-size: 16px;
    line-height: 24px;
    padding: 16px;
    border-radius: 10px;
    background: #f8fdf8;
    &-day-welcome {
      font-size: 14px;
      line-height: 22px;
      margin-top: 12px;
      max-width: 245px;
    }
  }

  .link-list {
    display: flex;
    flex-direction: column;
    > li {
      display: flex;
      align-items: center;
      &:not(:last-child) {
        margin-bottom: 8px;
      }

      svg {
        margin-right: 16px;
      }

      a {
        display: flex;
        align-items: center;
        padding: 8px;
        flex-grow: 1;
        &,
        &:link,
        &:visited,
        &:hover,
        &:active {
          text-decoration: none;
          color: #000000;
        }
      }
    }
  }

  .camp-channel {
    padding: 32px 0;
    background-color: #f8fdf8;
    h2 {
      font-size: 14px;
      line-height: 22px;
      font-family: "PoppinsRegular", sans-serif;
      font-weight: 400;
      margin-bottom: 10px;
      max-width: 235px;
    }

    &-vendor-container {
      a {
        display: flex;
        align-items: center;
        margin-top: 10px;
        font-size: 12px;
        line-height: 24px;
        &,
        &:link,
        &:visited,
        &:hover,
        &:active {
          color: #0b0204;
          text-decoration: none;
        }

        img {
          margin-right: 8px;
        }
      }
    }
  }
`;
