import styled, { css } from "styled-components";
import { NarrowContainer } from "../../components/NarrowContainer";
import { BackButtonBar } from "../../components/utils/BackButtonBar";
import { ReactComponent as PhoneIcon24x24 } from "../../assets/icons/phone-24x24.svg";
import {
  EMERGENCY_NUMBERS_CONFIG,
  EmergencyNumberConfig,
} from "./numbers.config";
import {
  ContactInfoConfig,
  HOTEL_CONTACT_INFO,
  LOGISTICS_ORGANIZATIONAL_CONTACT_INFO,
  OTHER_PROBLEMS_CONTACT_INFOS,
} from "./contact-info.config";
import { ContentContainer } from "../../components/utils/ContentContainer";
import { MobileOnly } from "../../components/utils/MobileOnly";
import { DesktopOnly } from "../../components/utils/DesktopOnly";
import { H4Semibold } from "../../components/utils/H4Semibold";
import { BREAKPOINTS } from "../../constants/styles";

const NumbersHeading = styled.h2`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  font-family: "PoppinsRegular", sans-serif;
`;

interface ContactInfoProps extends BaseStyledComponentProps {
  contactInfo: ContactInfoConfig;
}

const UnstyledContactInfo = (props: ContactInfoProps) => {
  const {
    contactInfo: { img, name, number, position },
    className,
  } = props;

  return (
    <address className={className}>
      <div className="img-container">
        <img src={img} alt={`${name}`} />
      </div>
      <h3>{name}</h3>
      {position && <p className="position">{position}</p>}
      <a className="number" href={`tel:${number}`}>
        <span className="number-icon">
          <PhoneIcon24x24 />
        </span>
        <p>{number}</p>
      </a>
    </address>
  );
};

const ContactInfo = styled(UnstyledContactInfo)`
  display: grid;
  grid-template-columns: 104px 1fr;
  grid-template-rows: ${(props) =>
    props.contactInfo.position ? "1fr min-content 1fr" : "1fr 1fr"};
  grid-column-gap: 24px;
  h3,
  .position,
  .number {
    grid-column: 2 / 3;
  }

  .img-container {
    overflow: hidden;
    border-radius: 10px;
    grid-column: 1 / 2;
    grid-row: 1 / 4;
    width: 104px;
    height: 104px;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  h3 {
    font-size: 14px;
    line-height: 22px;
    color: #0b0204;
    font-family: "PoppinsRegular", sans-serif;
    font-weight: 400;
    align-self: flex-end;
  }

  .position {
    color: #565a67;
    font-size: 12px;
    line-height: 18px;
    margin: 4px 0 8px;
  }

  .number {
    display: flex;
    font-size: 14px;
    line-height: 22px;
    align-items: center;
    align-self: flex-start;
    &,
    &:link,
    &:visited,
    &:hover,
    &:active {
      text-decoration: none;
      color: #2c3141;
    }

    &-icon {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: #f5f5f6;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
    }
  }

  h3 + .number {
    margin-top: 8px;
  }
`;

interface EmergencyNumberProps extends BaseStyledComponentProps {
  emergencyNumber: EmergencyNumberConfig;
}

const UnstyledEmergencyNumber = (props: EmergencyNumberProps) => {
  const {
    className,
    emergencyNumber: { number, text },
  } = props;

  return (
    <address className={className}>
      <h3>{text}</h3>
      <a href={`tel:${number}`}>{number}</a>
    </address>
  );
};

const EmergencyNumber = styled(UnstyledEmergencyNumber)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3 {
    font-size: 14px;
    line-height: 22px;
    color: #2c3141;
    font-weight: 400;
    font-family: "PoppinsRegular", sans-serif;
  }

  a {
    font-family: "PoppinsSemiBold", sans-serif;
    font-weight: 600;
    background-color: #f5f5f6;
    border-radius: 5px;
    width: 55px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    &,
    &:link,
    &:visited,
    &:hover,
    &:active {
      color: #0b0204;
      text-decoration: none;
    }
  }
`;

interface EmergencyNumberListProps extends BaseStyledComponentProps {
  numbers: EmergencyNumberConfig[];
}

const UnstyledEmergencyNumberList = (props: EmergencyNumberListProps) => {
  const { numbers, className } = props;

  return (
    <ul className={className}>
      {numbers.map((number) => (
        <li key={number.number}>
          <EmergencyNumber emergencyNumber={number} />
        </li>
      ))}
    </ul>
  );
};

const EmergencyNumberList = styled(UnstyledEmergencyNumberList)`
  li {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
`;

interface ContactInfoListProps extends BaseStyledComponentProps {
  contactInfos: ContactInfoConfig[];
}

const UnstyledContactInfoList = (props: ContactInfoListProps) => {
  const { contactInfos, className } = props;

  return (
    <ul className={className}>
      {contactInfos.map((info) => (
        <li key={info.name}>
          <ContactInfo contactInfo={info} />
        </li>
      ))}
    </ul>
  );
};

const ContactInfoList = styled(UnstyledContactInfoList).attrs(
  (props: { $isDesktopGrid: boolean }) => ({
    $isDesktopGrid: props.$isDesktopGrid || false,
  })
)`
  ${(props) => {
    if (props.$isDesktopGrid) {
      return css`
        @media ${BREAKPOINTS.DESKTOP} {
          display: grid;
          grid-template-columns: repeat(3, 316px);
          justify-content: space-between;
          grid-gap: 48px 40px;
        }
      `;
    } else {
      return css``;
    }
  }}

  li:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const ContactInfoBlock = styled.article.attrs(
  (props: { noMargin: boolean }) => ({
    noMargin: props.noMargin || false,
  })
)`
  margin-bottom: ${(props) => (props.noMargin ? 0 : "16px")};
  @media ${BREAKPOINTS.DESKTOP} {
    margin-bottom: 0;
  }
`;

interface NumbersProps extends BaseStyledComponentProps {}

const UnstyledNumbers = (props: NumbersProps) => {
  const { className } = props;

  return (
    <ContentContainer>
      <div className={className}>
        <MobileOnly>
          <BackButtonBar />
        </MobileOnly>

        <NarrowContainer>
          <DesktopOnly>
            <H4Semibold>Phone numbers</H4Semibold>
          </DesktopOnly>

          <div className="numbers-list-container">
            <div className="emergency-numbers">
              <ContactInfoBlock>
                <NumbersHeading>Emergency numbers in Poland</NumbersHeading>
                <EmergencyNumberList numbers={EMERGENCY_NUMBERS_CONFIG} />
              </ContactInfoBlock>
            </div>

            <div className="logistics-numbers">
              <ContactInfoBlock>
                <NumbersHeading>
                  Logistics and organizational issues
                </NumbersHeading>
                <ContactInfo
                  contactInfo={LOGISTICS_ORGANIZATIONAL_CONTACT_INFO}
                />
              </ContactInfoBlock>
            </div>

            <div className="hotel-numbers">
              <ContactInfoBlock>
                <NumbersHeading>B&B Hotel Wrocław Centrum</NumbersHeading>
                <ContactInfo contactInfo={HOTEL_CONTACT_INFO} />
              </ContactInfoBlock>
            </div>

            <div className="other-numbers">
              <ContactInfoBlock noMargin>
                <NumbersHeading>Other issues</NumbersHeading>
                <ContactInfoList
                  contactInfos={OTHER_PROBLEMS_CONTACT_INFOS}
                  $isDesktopGrid={true}
                />
              </ContactInfoBlock>
            </div>
          </div>
        </NarrowContainer>
      </div>
    </ContentContainer>
  );
};

export const Numbers = styled(UnstyledNumbers)`
  .numbers-list-container {
    margin-top: 10px;
    @media ${BREAKPOINTS.DESKTOP} {
      margin-top: 35px;
      display: grid;
      grid-template-columns: repeat(3, 316px);
      justify-content: space-between;
      grid-gap: 48px 40px;
      grid-template-areas:
        "emergency-numbers logistics-numbers hotel-numbers"
        "other-numbers     other-numbers     other-numbers";
    }
  }

  .emegency-numbers {
    grid-area: emergency-numbers;
  }

  .logistics-numbers {
    grid-area: logistics-numbers;
  }

  .hotel-numbers {
    grid-area: hotel-numbers;
  }

  .other-numbers {
    grid-area: other-numbers;
  }

  ${NumbersHeading} {
    margin-bottom: 16px;
  }

  ${EmergencyNumberList} {
    margin-bottom: 40px;
    @media ${BREAKPOINTS.DESKTOP} {
      margin-bottom: 0;
    }
  }
`;
