import styled from "styled-components";

interface SpacerProps {
  x?: number;
  y?: number;
}

export const Spacer = styled.span.attrs((props: SpacerProps) => ({
  x: props.x || 0,
  y: props.y || 0,
}))`
  transform: translate(${(props) => props.x}px, ${(props) => props.y}px);
`;
