import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { AnimatePresence, motion } from "framer-motion";
import { Container } from "../../components/Container";
import { BackButtonBar } from "../../components/utils/BackButtonBar";
import { Tag } from "../../components/Tag";
import {
  RestaurantConfig,
  RESTAURANTS_LIST,
  RESTAURANT_TYPES,
} from "./restaurants.config";
import { ReactComponent as LocationIcon22x22 } from "../../assets/icons/location-22x22.svg";
import { useDesktop } from "../../hooks/useDesktop";
import { MobileOnly } from "../../components/utils/MobileOnly";
import { DesktopOnly } from "../../components/utils/DesktopOnly";
import { H4Semibold } from "../../components/utils/H4Semibold";
import { ContentContainer } from "../../components/utils/ContentContainer";
import { BREAKPOINTS } from "../../constants/styles";

interface RestaurantProps extends BaseStyledComponentProps {
  restaurant: RestaurantConfig;
}

const UnstyledRestaurant = (props: RestaurantProps) => {
  const {
    restaurant: { description, img, name, location, menuUrl },
    className,
  } = props;

  return (
    <article className={className}>
      <header>
        <h2>{name}</h2>
        <a href={menuUrl} target="_blank" rel="noreferrer" className="link">
          Menu
        </a>
      </header>
      <p>{description}</p>
      <a
        href={location.mapUrl}
        target="_blank"
        rel="noreferrer"
        className="link location"
      >
        <span className="icon">
          <LocationIcon22x22 />
        </span>
        {location.title}
      </a>
      <div className="img-container">
        <img src={img} alt={name} />
      </div>
    </article>
  );
};

const Restaurant = styled(UnstyledRestaurant)`
  display: flex;
  flex-direction: column;
  color: #0b0204;
  header {
    display: flex;
    align-items: baseline;
    margin-bottom: 8px;
    h2 {
      font-size: 16px;
      line-height: 24px;
      font-family: "PoppinsMedium", sans-serif;
      font-weight: 500;
      margin-right: 16px;
    }
  }

  p {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 20px;
  }

  .icon {
    display: inline-flex;
    margin-right: 4px;
  }

  .location {
    display: flex;
    align-items: center;
  }

  .link {
    font-size: 14px;
    line-height: 18px;
    &,
    &:link,
    &:visited,
    &:hover,
    &:active {
      color: #0b0204;
      text-decoration: underline;
    }
  }

  .img-container {
    width: 312px;
    height: 176px;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 16px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

interface RestaurantListProps extends BaseStyledComponentProps {
  restaurants: RestaurantConfig[];
}

const UnstyledRestaurantList = (props: RestaurantListProps) => {
  const { className, restaurants } = props;

  return (
    <ul className={className}>
      <AnimatePresence presenceAffectsLayout mode="popLayout">
        {restaurants.map((restaurant) => (
          <motion.li
            layout
            key={restaurant.name}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Restaurant restaurant={restaurant} />
          </motion.li>
        ))}
      </AnimatePresence>
    </ul>
  );
};

const RestaurantsList = styled(UnstyledRestaurantList)`
  display: flex;
  flex-direction: column;
  @media ${BREAKPOINTS.DESKTOP} {
    display: grid;
    grid-template-columns: repeat(3, 312px);
    justify-content: space-between;
    grid-gap: 48px 40px;
  }

  li:not(:last-child) {
    margin-bottom: 48px;
    @media ${BREAKPOINTS.DESKTOP} {
      margin: 0;
    }
  }
`;

interface RestaurantsProps extends BaseStyledComponentProps {}

const makeTitle = (str: string) => str[0].toUpperCase() + str.slice(1);

const RESTAUTRANT_FILTER_TYPES = ["all", ...RESTAURANT_TYPES] as const;
const restaurantTypesCountRecord = RESTAURANTS_LIST.reduce(
  (acc, item) => {
    item.type.forEach((type) => {
      if (type in acc) {
        acc[type]++;
      } else {
        acc[type] = 1;
      }
    });

    return acc;
  },
  {
    all: RESTAURANTS_LIST.length,
  } as Record<typeof RESTAURANT_TYPES[number] | "all", number>
);

const UnstyledRestaurants = (props: RestaurantsProps) => {
  const { className } = props;
  const [currentRestaurantType, setCurrentRestaurantType] =
    useState<typeof RESTAUTRANT_FILTER_TYPES[number]>("all");
  const isDesktop = useDesktop();
  const filteredRestaurants = useMemo(
    () =>
      currentRestaurantType === "all"
        ? RESTAURANTS_LIST
        : RESTAURANTS_LIST.filter((restaurant) =>
            restaurant.type.includes(currentRestaurantType)
          ),
    [currentRestaurantType]
  );

  useEffect(() => {
    setCurrentRestaurantType("all");
  }, [isDesktop]);

  return (
    <ContentContainer>
      <div className={className}>
        <MobileOnly>
          <BackButtonBar />
          <ul className="filter-list">
            {RESTAUTRANT_FILTER_TYPES.map((rt) => (
              <li key={rt}>
                <Tag
                  active={rt === currentRestaurantType}
                  tag="button"
                  onClick={() => setCurrentRestaurantType(rt)}
                >
                  {makeTitle(rt)} ({restaurantTypesCountRecord[rt]})
                </Tag>
              </li>
            ))}
          </ul>
        </MobileOnly>

        <Container>
          <DesktopOnly>
            <H4Semibold>Restaurant Guide</H4Semibold>
          </DesktopOnly>

          <div className="restaurant-list-container">
            <RestaurantsList restaurants={filteredRestaurants} />
          </div>
        </Container>
      </div>
    </ContentContainer>
  );
};

export const Restaurants = styled(UnstyledRestaurants)`
  .restaurant-list-container {
    margin-top: 20px;
    @media ${BREAKPOINTS.DESKTOP} {
      margin-top: 40px;
    }
  }

  .filter-list {
    display: flex;
    overflow-x: scroll;
    padding: 16px 0;
    > li {
      &:first-child {
        margin-left: 32px;
      }

      &:last-child {
        margin-right: 32px;
      }

      &:not(:last-child) {
        margin-right: 15px;
      }
    }
  }
`;
