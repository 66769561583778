import styled, { createGlobalStyle } from "styled-components";
import { Container } from "../../../components/Container";
import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard } from "swiper";
import {
  DAY_1_EVENT_LIST_CONFIG,
  DAY_2_EVENT_LIST_CONFIG,
  DAY_3_EVENT_LIST_CONFIG,
  DAY_4_EVENT_LIST_CONFIG,
  DAY_5_EVENT_LIST_CONFIG,
  EventConfig,
} from "../event.config";
import { ReactComponent as CameraIcon22x22 } from "../../../assets/icons/camera-22x22.svg";
import { ReactComponent as CoffeeIcon22x22 } from "../../../assets/icons/coffee-22x22.svg";
import { ReactComponent as DiningRoomIcon22x22 } from "../../../assets/icons/dining-room-22x22.svg";
import { ReactComponent as WorkshopIcon22x22 } from "../../../assets/icons/workshop-22x22.svg";
import { ReactComponent as LocationIcon22x22 } from "../../../assets/icons/location-22x22.svg";
import { ReactComponent as ClockIcon22x22 } from "../../../assets/icons/clock-16x16.svg";
import { Link, useParams } from "react-router-dom";
import { NarrowContainer } from "../../../components/NarrowContainer";
import { Spacer } from "../../../components/utils/Spacer";
import { ConditionalWrapper } from "../../../components/utils/ConditionalWrapper";
import { H4Semibold } from "../../../components/utils/H4Semibold";
import { CalendarPicker } from "../Calendar";
import { useCampDay } from "../../../hooks/useCampDay";

const resolveEventTypeBgColor = (type: EventConfig["type"]) => {
  switch (type) {
    case "cafe":
    case "food": {
      return "#EAEEFB";
    }
    case "workshop": {
      return "#FBF0DD";
    }
    case "outdoor": {
      return "#FFB9C6";
    }
    case "studio_outdoor":
    case "studio": {
      return "#EAF6E8";
    }
  }
};

interface EventTypeCmpProps {
  type: EventConfig["type"];
}

const TypeIcon = (props: EventTypeCmpProps) => {
  const { type } = props;

  switch (type) {
    case "cafe": {
      return <CoffeeIcon22x22 />;
    }
    case "food": {
      return <DiningRoomIcon22x22 />;
    }
    case "workshop": {
      return <WorkshopIcon22x22 />;
    }
    case "outdoor": {
      return <LocationIcon22x22 />;
    }
    case "studio": {
      return <CameraIcon22x22 />;
    }
    case "studio_outdoor": {
      return <CameraIcon22x22 />;
    }
  }
};

const LocationText = (props: EventTypeCmpProps) => {
  const { type } = props;

  switch (type) {
    case "cafe": {
      return <>Cafeteria, </>;
    }
    case "workshop": {
      return <>Work area, </>;
    }
    case "outdoor": {
      return <>Outdoor</>;
    }
    case "studio": {
      return <>Studio, </>;
    }
    case "studio_outdoor": {
      return <>Outdoor</>;
    }
    case "food": {
      return <>Dining room, </>;
    }
  }
};

interface EventProps extends BaseStyledComponentProps {
  event: EventConfig;
}

const resolveSpacerTextPropsForType = (
  type: EventConfig["type"]
): React.ComponentProps<typeof Spacer> => {
  switch (type) {
    case "cafe": {
      return { x: 0, y: 1 };
    }
    default: {
      return { x: 0, y: 0 };
    }
  }
};

const UnstyledEvent = (props: EventProps) => {
  const {
    event: { text, time, auxLocation, type },
    className,
  } = props;

  return (
    <article className={className}>
      <h3>{text}</h3>
      <div className="location-time-container">
        <span className="location">
          <span className="location-icon">{<TypeIcon type={type} />}</span>
          <Spacer {...resolveSpacerTextPropsForType(type)}>
            <LocationText type={type} />
            {auxLocation && `${auxLocation}`}
          </Spacer>
        </span>
        <span className="time">
          <span className="time-icon">
            <ClockIcon22x22 />
          </span>
          {time}
        </span>
      </div>
    </article>
  );
};

const Event = styled(UnstyledEvent)`
  display: flex;
  flex-wrap: wrap;
  padding: 16px 14px 16px 20px;
  border-radius: 5px;
  background-color: ${(props) => resolveEventTypeBgColor(props.event.type)};
  h3 {
    font-size: 14px;
    line-height: 20px;
    flex-basis: 100%;
    color: #2c3141;
    font-family: "PoppinsMedium", sans-serif;
    font-weight: 500;
  }

  .time {
    font-size: 10px;
    line-height: 16px;
    color: #565a67;
    &-icon {
      display: flex;
      margin-right: 2px;
    }
  }

  .location {
    font-size: 10px;
    line-height: 16px;
    text-transform: uppercase;
    font-family: "PoppinsMedium", sans-serif;
    color: #2c3141;
    font-weight: 500;
    &-icon {
      display: flex;
      margin-right: 4px;
    }
  }

  .location-time-container {
    display: flex;
    justify-content: space-between;
    flex-basis: 100%;
    margin-top: 6px;
  }

  .time,
  .location {
    display: flex;
    align-items: center;
  }
`;

const resolveEventList = (day?: string) => {
  switch (day) {
    case "1": {
      return DAY_1_EVENT_LIST_CONFIG;
    }
    case "2": {
      return DAY_2_EVENT_LIST_CONFIG;
    }
    case "3": {
      return DAY_3_EVENT_LIST_CONFIG;
    }
    case "4": {
      return DAY_4_EVENT_LIST_CONFIG;
    }
    case "5": {
      return DAY_5_EVENT_LIST_CONFIG;
    }
    default: {
      return DAY_1_EVENT_LIST_CONFIG;
    }
  }
};

const resolveHrefByEventType = (type: EventConfig["type"]) => {
  switch (type) {
    case "studio": {
      return `/office-map#studio`;
    }
    case "workshop": {
      return `/office-map#work-area`;
    }
    case "cafe": {
      return `/office-map#cafeteria`;
    }
    case "food": {
      return `/office-map#dining-room`;
    }
    default: {
      return undefined;
    }
  }
};

const useDayFromPath = () => {
  const { day } = useParams();
  return day;
};

interface EventListProps extends BaseStyledComponentProps {
  events: EventConfig[];
  shouldHaveLinks?: boolean;
}

const UnstyledEventList = (props: EventListProps) => {
  const { events, className, shouldHaveLinks = false } = props;

  return (
    <ul className={className}>
      {events.map((event, i) => (
        <li key={event.text}>
          <ConditionalWrapper
            shouldWrap={
              shouldHaveLinks
                ? Boolean(resolveHrefByEventType(event.type))
                : false
            }
            wrapper={(children) => (
              <Link
                to={resolveHrefByEventType(event.type)!}
                className="event-link"
              >
                {children}
              </Link>
            )}
          >
            <Event event={event} />
          </ConditionalWrapper>
        </li>
      ))}
    </ul>
  );
};

const EventList = styled(UnstyledEventList)`
  .event-link {
    &,
    &:link,
    &:visited,
    &:hover,
    &:active {
      text-decoration: none;
    }
  }

  li:not(:last-child) {
    margin-bottom: 16px;
  }
`;

interface MobileEventListProps extends BaseStyledComponentProps {}

const UnstyledMobileEventList = (props: MobileEventListProps) => {
  const { className } = props;
  const day = useDayFromPath();
  const events = resolveEventList(day);
  const campDay = useCampDay();

  return (
    <div className={className}>
      <NarrowContainer>
        <div className="info">
          <span>{events.length} events</span>
          <Link to="/office-map">Office map</Link>
        </div>
      </NarrowContainer>
      <Container>
        <EventList events={events} shouldHaveLinks={campDay === Number(day)} />
      </Container>
    </div>
  );
};

export const MobileEventList = styled(UnstyledMobileEventList)`
  margin-top: -30px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  background-color: #fff;
  padding: 16px 0 0;
  display: flex;
  flex-direction: column;
  .info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    > span {
      font-size: 14px;
      line-height: 18px;
    }

    > a {
      &,
      &:link,
      &:visited,
      &:hover,
      &:active {
        color: #000;
        text-decoration: underline;
      }

      font-size: 14px;
      line-height: 18px;
    }
  }
`;

interface EventSlideProps extends BaseStyledComponentProps {
  isCurrent?: boolean;
  date: string;
  day: string;
  events: EventConfig[];
}

const UnstyledEventSlide = (props: EventSlideProps) => {
  const { className, events, date, day, isCurrent } = props;

  return (
    <article className={className}>
      <header>
        <h2>{date}</h2>
        <p className="events-info">{events.length} events </p>
        <p className="day">{day}</p>
      </header>
      <EventList events={events} shouldHaveLinks={isCurrent} />
    </article>
  );
};

const EventSlide = styled(UnstyledEventSlide).attrs(
  (props: EventSlideProps) => ({
    isCurrent: props.isCurrent || false,
  })
)`
  width: 376px;
  padding: 24px 20px;
  transition: border-color 0.2 ease-in-out;
  border: 1px solid ${(props) => (props.isCurrent ? "#45be31" : "transparent")};
  border-radius: 20px;
  header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 24px;
    .day {
      flex-basis: 100%;
      margin-top: 6px;
    }
  }

  h2 {
    font-size: 18px;
    line-height: 28px;
    font-family: "PoppinsRegular", sans-serif;
    font-weight: 400;
  }

  .day {
    font-size: 14px;
    line-height: 22px;
    font-family: "PoppinsSemiBold", sans-serif;
    font-weight: 600;
  }
`;

const SwiperOverloadsStyles = createGlobalStyle`
  .swiper {
    overflow: visible;
  }
  
  .swiper-slide {
    width: 376px;
  }
`;

interface DesktopEventListProps extends BaseStyledComponentProps {}

const UnstyledDesktopEventList = (props: DesktopEventListProps) => {
  const { className } = props;
  const day = useDayFromPath();

  return (
    <div className={className}>
      <Container>
        <header>
          <H4Semibold>Calendar & schedule</H4Semibold>
          <CalendarPicker />
        </header>
        <SwiperOverloadsStyles />

        <Swiper
          grabCursor={true}
          initialSlide={Number(day) - 1}
          spaceBetween={20}
          slidesPerView="auto"
          modules={[Keyboard]}
          keyboard={{
            enabled: true,
          }}
        >
          <SwiperSlide>
            <EventSlide
              date="12 September"
              day="Monday"
              events={DAY_1_EVENT_LIST_CONFIG}
              isCurrent={day === "1"}
            />
          </SwiperSlide>
          <SwiperSlide>
            <EventSlide
              date="13 September"
              day="Tuesday"
              events={DAY_2_EVENT_LIST_CONFIG}
              isCurrent={day === "2"}
            />
          </SwiperSlide>
          <SwiperSlide>
            <EventSlide
              date="14 September"
              day="Wednesday"
              events={DAY_3_EVENT_LIST_CONFIG}
              isCurrent={day === "3"}
            />
          </SwiperSlide>
          <SwiperSlide>
            <EventSlide
              date="15 September"
              day="Thursday"
              events={DAY_4_EVENT_LIST_CONFIG}
              isCurrent={day === "4"}
            />
          </SwiperSlide>
          <SwiperSlide>
            <EventSlide
              date="16 September"
              day="Friday"
              events={DAY_5_EVENT_LIST_CONFIG}
              isCurrent={day === "5"}
            />
          </SwiperSlide>
        </Swiper>
      </Container>
    </div>
  );
};

export const DesktopEventList = styled(UnstyledDesktopEventList)`
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    z-index: 2;
    position: relative;
    ${H4Semibold} {
      flex-grow: 1;
    }

    ${CalendarPicker} {
      flex-basis: 312px;
    }
  }
`;
