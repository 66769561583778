import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as ArrowLeftIcon32x32 } from "../../assets/icons/arrow-left-32x32.svg";
import { NarrowContainer } from "../NarrowContainer";

interface BackButtonBarProps extends BaseStyledComponentProps {}

const UnstyledBackButtonBar = (props: BackButtonBarProps) => {
  const { className } = props;
  const navigate = useNavigate();

  return (
    <nav className={className} aria-label="Previous page navigation">
      <NarrowContainer>
        <button onClick={() => navigate(-1)}>
          <ArrowLeftIcon32x32 />
        </button>
      </NarrowContainer>
    </nav>
  );
};

export const BackButtonBar = styled(UnstyledBackButtonBar)`
  display: flex;
  padding: 8px 0;

  button {
    background-color: #f9f9f9;
    width: 36px;
    height: 36px;
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
  }
`;
